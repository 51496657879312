import React from "react";
import './style.scss';
import TopBanner from "../../components/topBanner";
import banner from '../../../../assets/images/top_bg4@2x.png'
import banner_2 from '../../../../assets/mobile/top_bg4@3x.png'
import AchievementSwiper from "../../components/achievementSwiper";
import CooperativePartner from "../../components/cooperativePartner";
import isMobile from "ismobilejs";

const CooperationPage = () => {
  return <div className={'CooperationPage'}>
    <TopBanner icon={isMobile(window.navigator).phone ? banner_2 : banner} title={'战略合作'} english={'COOPERATE'}/>
    <div className={'content topCooperation'}>
      <div className={'title'}>
        联合驱动：推动家庭数字化服务快速发展
      </div>
      <AchievementSwiper/>
    </div>
    <CooperativePartner/>
  </div>
}
export default CooperationPage;
