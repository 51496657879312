import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import icon from "../../../../assets/images/top_bg6@2x.jpg";
import icon_2 from "../../../../assets/mobile/top_bg6@3x.png";
import { Routes } from "../../../../utils/routes";
import { Switch, Route, withRouter } from "react-router-dom";
import JoinUsIndexPage from "./index/index";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import store, { JOBS, JOBS_MENUS } from "../../../../utils/store";
import { useMounted } from "../../../../utils/hook";
import isMobile from "ismobilejs";

const JoinUsPage = () => {

	const [menus, setMenus] = useState([]);

	const isMounted = useMounted();

	useEffect(() => {
		const getList = async () => {
			const res = await Axios({
				url: Apis.jobs,
			})
			const { code = 200, data = [] } = res;
			if (code !== 200 || !isMounted) return false;
			const menus = data.map((item, index) => ({
				title: item.name, path: `${Routes.joinUsIndex}/${item.id}`
			}));
			store.setArray(JOBS, data);
			store.setArray(JOBS_MENUS, menus);
			setMenus(menus);
		}

		(async () => {
			await getList();
		})();

	}, []);

	return <Layout english={'JOINUS'} title={'加入我们'} icon={isMobile(window.navigator).phone ? icon_2 : icon}
	               menus={[{
		               title: '全部岗位', path: `${Routes.joinUsIndex}/0`
	               }, ...menus]}>
		<Switch>
			<Route path={`${Routes.joinUsIndex}/:type`} component={JoinUsIndexPage}/>
		</Switch>
	</Layout>;
}
export default withRouter(JoinUsPage);
