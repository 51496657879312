import volunteer_01 from "../../../../assets/base/base_logo1@2x.png";
import volunteer_02 from "../../../../assets/base/base_logo2@2x.png";

const volunteers = [
  { key: "1", logo: volunteer_01 },
  { key: "2", logo: volunteer_02 },
];
const VolunteerBase = () => {
  return (
    <div className="tab-partner-content">
      {volunteers.map((ele) => {
        return (
          <div className="tab-item" key={ele.key}>
            <img src={ele.logo} alt="" />
          </div>
        );
      })}
    </div>
  );
};

export default VolunteerBase;
