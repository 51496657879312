import React, { useEffect, useState } from "react";
import banner from "../../../../../assets/images/top_bg_dybamic.png";
import banner_2 from "../../../../../assets/mobile/top_bg_dybamic@3x.png";
import TopBanner from "../../../components/topBanner";
import "./style.scss";
import "./ckeditor.scss";
import isMobile from "ismobilejs";
import Axios from "../../../../../utils/axios";
import Apis from "../../../../../utils/apis";
import { useMounted } from "../../../../../utils/hook";
import store, { NEW_DETAIL } from "../../../../../utils/store";
import moment from "moment";

const NewsDetailPage = (props) => {
  const [data, setData] = useState({ content: "" });

  const isMounted = useMounted();
  useEffect(() => {
    setData(store.getObject(NEW_DETAIL));

    const getData = async () => {
      const res = await Axios({
        url: `${Apis.news}/${props.match.params.id}`,
        params: {
          page: props.match.params.page,
        },
      });
      const { data = {}, code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      setData(data);
    };
    (async () => {
      await getData();
    })();
  }, []);

  return (
    <div className={"NewsDetailPage"}>
      <TopBanner
        icon={isMobile(window.navigator).phone ? banner_2 : banner}
        title={"公司动态"}
        english={"DYNAMIC"}
      />
      <div className={"content NewsDetailPageContent"}>
        <div
          className={"back"}
          onClick={() => {
            window.history.go(-1);
          }}
        />
        <div className="content_bg">
          <div className={"title"}>{data.title}</div>
          <div className={"time"}>
            {moment(data.publish_at).format("YYYY年MM月DD日")}
          </div>
          <article
            className={"newContentArticle ck-content"}
            dangerouslySetInnerHTML={{
              __html: data?.content
                ? data?.content.replace(
                    /(["'])(\/joinuscn-admin\/|\/joinuscn-admin-dev\/)/g,
                    `$1${process.env["ASSETS_URL"]}$2`
                  )
                : "",
            }}
          />
          <div
            className={"back_mobile"}
            onClick={() => {
              window.history.go(-1);
            }}
          >
            返回
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsDetailPage;
