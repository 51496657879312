class Routes {
  static root = `/pc/`;
  static home = `${this.root}home`;
  //公司简介
  static companyProfile = `${this.root}companyProfile`;
  static companyProfileIndex = `${this.companyProfile}/index`;
  static companyProfileHistory = `${this.companyProfile}/history`;
  static companyProfileManagementTeam = `${this.companyProfile}/managementTeam`;
  static companyProfileTeamBuilding = `${this.companyProfile}/teamBuilding`;
  static companyProfileHonor = `${this.companyProfile}/honor`;
  //战略合作
  static cooperation = `${this.root}cooperation`;
  //解决方案新版
  static solutionV2 = `${this.root}solutionV2`;
  //解决方案
  static solution = `${this.root}solution`;
  static solutionIndex = `${this.solution}/index`;
  static solutionEducationalInnovation = `${this.solution}/educationalInnovation`;
  static solutionK12ServiceCentre = `${this.solution}/k12ServiceCentre`;
  static solutionBigData = `${this.solution}/bigData`;
  static solutionDigitalMedicine = `${this.solution}/digitalMedicine`;
  static solutionDigitalFinance = `${this.solution}/digitalFinance`;
  static solutionPublicWelfare = `${this.solution}/publicWelfare`;
  bigData;
  //一本书公益
  static socialWelfare = `${this.root}socialWelfare`;
  //招贤纳士
  static joinUs = `${this.root}joinUs`;
  static joinUsHome = `${this.joinUs}Home`;
  static joinUsIndex = `${this.joinUs}/index`;
  //公司动态
  static news = `${this.root}news`;
  static newsDetail = `${this.root}newsDetail`;

  //大数据乘车安
  //大数据公务柜
  static solutionBigDataBusinessCabinet = `${this.solution}businessCabinet`;
  static solutionBigDataRideSafety = `${this.solution}rideSafety`;
}

export { Routes };
