import React, { useEffect, useState } from "react";
import "./style.scss";
import "./indexMobile.scss";
import logo_1 from "../../../../assets/images/school_logo01@2x.png";
import logo_2 from "../../../../assets/images/school_logo02@2x.png";
import logo_3 from "../../../../assets/images/school_logo03@2x.png";
import logo_4 from "../../../../assets/images/school_logo04@2x.png";
import logo_5 from "../../../../assets/images/school_logo05@2x.png";
import logo_6 from "../../../../assets/images/school_logo06@2x.png";
import logo_7 from "../../../../assets/images/school_logo07@2x.png";
import logo_8 from "../../../../assets/images/school_logo08@2x.png";
import logo_9 from "../../../../assets/images/school_logo09@2x.png";
import logo_10 from "../../../../assets/images/school_logo10@2x.png";
import logo_11 from "../../../../assets/images/school_logo11@2x.png";
import logo_12 from "../../../../assets/images/school_logo12@2x.png";
import logo_13 from "../../../../assets/images/school_logo13@2x.png";
import logo_14 from "../../../../assets/images/school_logo14@2x.png";
import logo_15 from "../../../../assets/images/school_logo15@2x.png";
import logo_16 from "../../../../assets/images/school_logo16@2x.png";
import WalkingLantern from "../walkingLantern";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import { useMounted } from "../../../../utils/hook";
import _ from "lodash";
import isMobile from "ismobilejs";

const CustomerCase = () => {
  const isMounted = useMounted();
  const [listOne, setListOne] = useState([]);
  const [listTwo, setListTwo] = useState([]);
  function splitArrayInHalf(array) {
    const half = Math.ceil(_.size(array) / 2);
    return _.chunk(array, half);
  }
  useEffect(() => {
    const getData = async () => {
      const res = await Axios({
        url: Apis.schools,
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      const modifyList = _.map(data, (item) => {
        return `${process.env["ASSETS_URL"]}${item.url}`;
      });
      const result = splitArrayInHalf(modifyList);
      if (_.size(result) == 2) {
        setListOne(_.concat(result[0], result[0]));
        setListTwo(_.concat(result[1], result[1]));
      }
    };

    (async () => {
      await getData();
    })();
  }, []);
  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "CustomerCase_mobile"
          : "CustomerCase"
      }
    >
      <div className={"customerCaseTitle"}>服务学校</div>
      <div className={"customerCaseContent"}>
        <div className={"ani"}>
          <WalkingLantern
            data={
              _.size(listOne) > 0
                ? listOne
                : [
                    logo_1,
                    logo_2,
                    logo_3,
                    logo_4,
                    logo_5,
                    logo_6,
                    logo_13,
                    logo_14,
                    logo_1,
                    logo_2,
                    logo_3,
                    logo_4,
                    logo_5,
                    logo_6,
                    logo_13,
                    logo_14,
                    logo_1,
                    logo_2,
                    logo_3,
                    logo_4,
                    logo_5,
                    logo_6,
                    logo_13,
                    logo_14,
                  ]
            }
            itemStyle={
              isMobile(window.navigator).phone
                ? { width: 80, height: 60, marginLeft: 12, marginTop: 10 }
                : {}
            }
            animation={"loop infinite linear 60s"}
          />
        </div>
        <div className={"ani"}>
          <div className={"ani"}>
            <WalkingLantern
              data={
                _.size(listTwo) > 0
                  ? listTwo
                  : [
                      logo_7,
                      logo_8,
                      logo_9,
                      logo_10,
                      logo_11,
                      logo_12,
                      logo_15,
                      logo_16,
                      logo_7,
                      logo_8,
                      logo_9,
                      logo_10,
                      logo_11,
                      logo_12,
                      logo_15,
                      logo_16,
                      logo_7,
                      logo_8,
                      logo_9,
                      logo_10,
                      logo_11,
                      logo_12,
                      logo_15,
                      logo_16,
                    ]
              }
              itemStyle={
                isMobile(window.navigator).phone
                  ? { width: 80, height: 60, marginLeft: 12, marginTop: 10 }
                  : {}
              }
              animation={"loop infinite linear 60s"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerCase;
