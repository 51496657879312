import React, { useEffect, useState } from "react";
import "./style.scss";
import "./indexMobile.scss";
import icon_1 from "../../../../../assets/images/course_img1@2x.png";
import icon_2 from "../../../../../assets/images/course_img2@2x.png";
import icon_3 from "../../../../../assets/images/course_img3@2x.png";
import icon_4 from "../../../../../assets/images/course_img4@2x.png";
import icon_5 from "../../../../../assets/images/course_img5@2x.png";
import icon_6 from "../../../../../assets/images/course_img6@2x.png";
import icon_7 from "../../../../../assets/images/course_img7@2x.png";
import icon_8 from "../../../../../assets/images/course_img8@2x.png";
import icon_9 from "../../../../../assets/images/course_img9@2x.png";
import last_blue from "../../../../../assets/home/last_blue.png";
import last_gray from "../../../../../assets/home/last_gray.png";
import next_blue from "../../../../../assets/home/next_blue.png";
import next_gray from "../../../../../assets/home/next_gray.png";
import _ from "lodash";
import isMobile from "ismobilejs";
const CompanyProfileHistoryPage = () => {
  const [list] = useState([
    {
      year: "2024",
      icon: icon_9,
      news: [
        {
          month: "1月",
          infos: ["爱家校荣获“河南省校园多元大数据工程技术研究中心”认定。"],
        },
      ],
    },
    {
      year: "2023",
      icon: icon_9,
      news: [
        {
          month: "1月",
          infos: ["一本书公益荣获“优秀志愿服务项目”。"],
        },
        {
          month: "4月",
          infos: ["爱家校成为河南省数字经济产业协会常务副会长单位。"],
        },
        {
          month: "5月",
          infos: ["打造K12校园安全生活服务平台，促进校园数字化建设。"],
        },
        {
          month: "8月",
          infos: ["一本书公益荣获“河南省2023年文明实践志愿服务优秀项目”。"],
        },
        {
          month: "9月",
          infos: ["荣获北京市“专精特新”企业认定。"],
        },
        {
          month: "11月",
          infos: [
            "全力推进K12校园学生安全管理平台建设，助力中小学校提高安全管理水平。",
          ],
        },
      ],
    },
    {
      year: "2022",
      icon: icon_9,
      news: [
        {
          month: "1月",
          infos: ["智能公务柜上线。"],
        },
        {
          month: "4月",
          infos: ["成为中外阅读学研究专业委员会理事单位。"],
        },
        {
          month: "10月",
          infos: ["智能公务柜获得国家发明专利认证。"],
        },
        {
          month: "11月",
          infos: ["一本书公益获得国家发明专利认证。"],
        },
        {
          month: "12月",
          infos: [
            "获得高新技术企业认定，爱家校荣获郑州市“专精特新”中小企业认定。",
          ],
        },
      ],
    },
    {
      year: "2021",
      icon: icon_1,
      news: [
        {
          month: "1月",
          infos: [
            "公司董事长郑义联合中国广播电视社会组织联合会全民国防教育宣传委员会会长郑志宏、河南广播电视台新闻事业部爱之声工作室负责人穆迪联合发起“一本书公益计划”。",
          ],
        },
        {
          month: "7月",
          infos: ["举办“码链融合数字身份一卡通乘车安应用首发式”。"],
        },
        {
          month: "12月",
          infos: ["发布《基于码链融合的数字身份应用架构团体标准》。"],
        },
      ],
    },
    {
      year: "2020",
      icon: icon_2,
      news: [
        {
          month: "3月",
          infos: ["向河南省教育厅爱心捐赠400万元校园防疫设备。"],
        },
        {
          month: "4月",
          infos: ["乘车安上线。"],
        },
        {
          month: "6月",
          infos: ["云校柜上线。"],
        },
        {
          month: "8月",
          infos: [
            "与中国信息安全研究院创立“中国信安·北京家音数字化转型与安全联合创新实验室”。",
            "共建“大数据分析与应用技术国家工程实验室网信智能中心”。",
          ],
        },
        {
          month: "9月",
          infos: ["云校柜获得国家发明专利认证。"],
        },
        {
          month: "12月",
          infos: [
            "交通运输部城市公共交通智能化交通运输行业重点实验室（郑州分中心）成立。",
            "“家音顺达-北工大数据创新服务及科研成果转化中心”成立。",
          ],
        },
      ],
    },
    {
      year: "2019",
      icon: icon_3,
      news: [
        {
          month: "7月",
          infos: ["作文提分上线。"],
        },
        {
          month: "10月",
          infos: ["荣获“吴文俊人工智能科学技术奖”科技进步奖一等奖。"],
        },

        // { month: "7月", infos: ["独家研发的——校园访客智能管理系统正式上线。"] },
        // {
        //   month: "5月",
        //   infos: [
        //     "独家承办石家庄市教育局主办的“美丽石家庄，创卫我先行”作文征集活动。",
        //   ],
        // },
        // {
        //   month: "1月",
        //   infos: ["自主研发的中小学生学习类小程序——作文提分正式上线。"],
        // },
      ],
    },
    {
      year: "2018",
      icon: icon_4,
      news: [
        {
          month: "7月",
          infos: ["青于蓝品牌创立。"],
        },
        {
          month: "9月",
          infos: ["举办河南省中小学生人工智能作文征文大赛（公益）。"],
        },
      ],
    },
    {
      year: "2017",
      icon: icon_5,
      news: [
        {
          month: "",
          infos: ["成为“双软企业”。", "发起“助成长行动”,为乡村孩子营养加餐。"],
        },
        // {
        //   month: "7月",
        //   infos: [
        //     "自主开发的“基于微信公众服务平台的在线点名考勤系统”正式上线。",
        //     "积极提交国家高新技术企业认证申请。",
        //   ],
        // },
      ],
    },
    {
      year: "2016",
      icon: icon_6,
      news: [
        { month: "", infos: ["校园移动支付平台上线。"] },
        // {
        //   month: "4月",
        //   infos: [
        //     "变更注册地址，为适应快速发展的公司规模，办公地点迁至郑东新区美侨世纪广场。",
        //   ],
        // },
        // {
        //   month: "3月",
        //   infos: ["“爱家校”APP正式上线，首次推广用户量即突破8万人。"],
        // },
        // { month: "2月", infos: ["家音顺达校园移动支付平台专利申请成功获批。"] },
        // { month: "1月", infos: ["家音顺达校园资源管理系统专利申请成功获批。"] },
      ],
    },
    {
      year: "2015",
      icon: icon_7,
      news: [
        {
          month: "",
          infos: ["爱家校APP上线。"],
        },
      ],
    },
    {
      year: "2014",
      icon: icon_8,
      news: [
        { month: "", infos: ["成为河南省内最大的校园信息化场景服务供应商。"] },
      ],
    },
    {
      year: "2013",
      icon: icon_1,
      news: [{ month: "", infos: ["成为中国移动重要合作伙伴。"] }],
    },
    {
      year: "2012",
      icon: icon_9,
      news: [
        {
          month: "",
          infos: ["初步形成河南省内市场业务布局。"],
        },
      ],
    },
    {
      year: "2011",
      icon: icon_1,
      news: [{ month: "", infos: ["推出中国移动i家校业务，爱家校品牌创立。"] }],
    },
    {
      year: "2010",
      icon: icon_1,
      news: [{ month: "", infos: ["家音顺达品牌创立。"] }],
    },
  ]);

  const [currentYear, setCurrentYear] = useState(list[0]);
  const [showYearList, setShowYearList] = useState(_.take(list, 3));
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    if (currentYear) {
      const targetIndex = list.findIndex(
        (obj) => obj.year === currentYear.year
      );
      setIsTop(targetIndex === 0);
      setIsBottom(targetIndex === list.length - 1);
    }
  }, [currentYear]);

  function getSurroundingObjects(array, target) {
    let targetIndex = array.findIndex((obj) => obj.year === target.year);

    if (targetIndex === 0) {
      return [
        array[targetIndex],
        array[targetIndex + 1],
        array[targetIndex + 2],
      ];
    }

    if (targetIndex === array.length - 1) {
      return [
        array[targetIndex - 2],
        array[targetIndex - 1],
        array[targetIndex],
      ];
    }

    return [array[targetIndex - 1], array[targetIndex], array[targetIndex + 1]];
  }

  const dealArrowClick = (type) => {
    if (type == "up") {
      const targetIndex = list.findIndex(
        (obj) => obj.year === currentYear.year
      );
      if (targetIndex === 0) {
        return;
      }
      const target = list[targetIndex - 1];
      dealChangeYear(target);
    }
    if (type == "down") {
      const targetIndex = list.findIndex(
        (obj) => obj.year === currentYear.year
      );
      if (targetIndex === list.length - 1) {
        return;
      }
      const target = list[targetIndex + 1];
      dealChangeYear(target);
    }
  };

  const dealChangeYear = (item) => {
    const modifyList = getSurroundingObjects(list, item);
    setShowYearList(modifyList);
    setCurrentYear(item);
  };

  const renderYearView = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div className="cphp_right_bg">
          <img
            className="cphp_right_top_icon"
            style={{ marginRight: 10 }}
            src={isTop ? last_gray : last_blue}
            onClick={() => {
              dealArrowClick("up");
            }}
          />
          {_.map(showYearList, (item, i) => {
            return (
              <div
                className={
                  item.year === currentYear.year
                    ? "cphp_right_selected_title"
                    : "cphp_right_title"
                }
                key={i}
                onClick={() => {
                  // setCurrentYear(item);
                  dealChangeYear(item);
                }}
              >
                {item.year}
              </div>
            );
          })}
          <img
            className="cphp_right_top_icon"
            style={{ marginLeft: 10 }}
            src={isBottom ? next_gray : next_blue}
            onClick={() => {
              dealArrowClick("down");
            }}
          />
        </div>
      );
    }
    return (
      <div className="cphp_right_bg">
        <img
          className="cphp_right_top_icon"
          style={{ marginBottom: 10 }}
          src={isTop ? last_gray : last_blue}
          onClick={() => {
            dealArrowClick("up");
          }}
        />
        {_.map(showYearList, (item, i) => {
          return (
            <div
              className={
                item.year === currentYear.year
                  ? "cphp_right_selected_title"
                  : "cphp_right_title"
              }
              key={i}
              onClick={() => {
                // setCurrentYear(item);
                dealChangeYear(item);
              }}
            >
              {item.year}
            </div>
          );
        })}
        <img
          className="cphp_right_top_icon"
          style={{ marginTop: 10 }}
          src={isBottom ? next_gray : next_blue}
          onClick={() => {
            dealArrowClick("down");
          }}
        />
      </div>
    );
  };
  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "CompanyProfileHistoryPage_mobile"
          : "CompanyProfileHistoryPage"
      }
    >
      {isMobile(window.navigator).phone && renderYearView()}
      <div className={"list"}>
        <div className={`item left `}>
          <div className={"infoBox"}>
            <div className={"news"}>
              {currentYear.news.map((item, x) => (
                <div key={`newsItem_${x}`} className={`newsItem`}>
                  <span className="newsItem_title">{item.month}</span>
                  <div>
                    {item.infos.map((e, i) => (
                      <div className={"newInfoItem"} key={i}>
                        {e}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {!isMobile(window.navigator).phone && renderYearView()}
    </div>
  );
};
export default CompanyProfileHistoryPage;
