import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Routes } from "./utils/routes";
import Pc from "./projects/pc";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
const App = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Redirect from={"/"} to={Routes.home} exact />
          <Redirect from={Routes.joinUs} to={Routes.joinUsHome} exact />
          {/*pc路由*/}
          <Route path={Routes.root} component={Pc} />
        </Switch>
      </Router>
    </div>
  );
};
export default Sentry.withProfiler(App);

// console.log("process.env", Object.entries(process.env));
