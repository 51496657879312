import { useMemo, useState } from "react";
import LoveSchool from "./LoveSchool";
import CommonwealPartner from "./CommonwealPartner";
import LoveEnterprise from "./LoveEnterprise";
import VolunteerBase from "./VolunteerBase";
import DonatedSchool from "./DonatedSchool";

import "./style.scss";

const tabs = [
  { title: "爱心学校", key: "1", component: <LoveSchool /> },
  { title: "公益伙伴", key: "2", component: <CommonwealPartner /> },
  { title: "爱心企业", key: "3", component: <LoveEnterprise /> },
  { title: "志愿者基地", key: "4", component: <VolunteerBase /> },
  { title: "受捐学校", key: "5", component: <DonatedSchool /> },
];

const CustomTab = () => {
  const [current, setCurrent] = useState("1");
  const currentComponent = useMemo(() => {
    return tabs.find((ele) => ele.key === current).component;
  }, [current]);
  return (
    <div className="custom-tab">
      <div className="tabs">
        {tabs.map((item) => {
          return (
            <div
              className={`tab-item ${item.key === current ? "active" : ""}`}
              key={item.key}
              onClick={() => setCurrent(item.key)}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      <div className="container">{currentComponent}</div>
    </div>
  );
};

export default CustomTab;
