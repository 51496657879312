import React from "react";
import Layout from "../../components/layout";
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import icon from "../../../../assets/images/top_bg3@2x.png";
import icon_2 from "../../../../assets/mobile/top_bg3@3x.png";
import {Routes} from "../../../../utils/routes";
import SolutionIndexPage from "./index/index";
import EducationalInnovationPage from "./educationalInnovation";
import K12ServiceCentrePage from "./k12ServiceCentre";
import BigDataPage from "./bigData/index";
import DigitalMedicinePage from "./digitalMedicine";
import DigitalFinancePage from "./digitalFinance";
import PublicWelfarePage from "./publicWelfare";
import isMobile from "ismobilejs";

const SolutionPage = () => {
  return <Layout english={'SOLUTION'} title={'解决方案'} icon={isMobile(window.navigator).phone ? icon_2 : icon} menus={[
    {
      title: '数字化校园',
      path: Routes.solutionIndex
    },
    {
      title: '均衡教育',
      path: Routes.solutionEducationalInnovation
    },
    {
      title: 'K12教育辅助',
      path: Routes.solutionK12ServiceCentre
    },
    {
      title: '公共大数据',
      path: Routes.solutionBigData
    },
    // {
    //   title: '数字化医疗',
    //   path: Routes.solutionDigitalMedicine
    // },
    // {
    //   title: '数字化金融',
    //   path: Routes.solutionDigitalFinance
    // },
    {
      title: '新型公益',
      path: Routes.solutionPublicWelfare
    },
  ]}>
    <Switch>
      <Redirect from={Routes.solution} to={Routes.solutionIndex} exact/>
      <Route path={Routes.solutionIndex} component={SolutionIndexPage}/>
      <Route path={Routes.solutionEducationalInnovation} component={EducationalInnovationPage}/>
      <Route path={Routes.solutionK12ServiceCentre} component={K12ServiceCentrePage}/>
      <Route path={Routes.solutionBigData} component={BigDataPage}/>
      <Route path={Routes.solutionDigitalMedicine} component={DigitalMedicinePage}/>
      <Route path={Routes.solutionDigitalFinance} component={DigitalFinancePage}/>
      <Route path={Routes.solutionPublicWelfare} component={PublicWelfarePage}/>
    </Switch>
  </Layout>;
};
export default withRouter(SolutionPage)
