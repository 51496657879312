import school_01 from "../../../../assets/school/school_logo1@2x.png";
import school_02 from "../../../../assets/school/school_logo2@2x.png";
import school_03 from "../../../../assets/school/school_logo3@2x.png";
import school_04 from "../../../../assets/school/school_logo4@2x.png";
import school_05 from "../../../../assets/school/school_logo5@2x.png";
import school_06 from "../../../../assets/school/school_logo6@2x.png";
import school_07 from "../../../../assets/school/school_logo7@2x.png";
import school_08 from "../../../../assets/school/school_logo8@2x.png";
import school_09 from "../../../../assets/school/school_logo9@2x.png";
import school_10 from "../../../../assets/school/school_logo10@2x.png";
import school_11 from "../../../../assets/school/school_logo11@2x.png";
import school_12 from "../../../../assets/school/school_logo12@2x.png";
import school_13 from "../../../../assets/school/school_logo13@2x.png";
import school_14 from "../../../../assets/school/school_logo14@2x.png";
import school_15 from "../../../../assets/school/school_logo15@2x.png";
import school_16 from "../../../../assets/school/school_logo16@2x.png";
import school_17 from "../../../../assets/school/school_logo17@2x.png";
import school_18 from "../../../../assets/school/school_logo18@2x.png";
const schools = [
  { name: "北京理工大学附属中学分校", icon: school_01 },
  { name: "北京润丰学校", icon: school_02 },
  { name: "河南省实验小学", icon: school_03 },
  { name: "郑州市中原区华山路小学", icon: school_04 },
  { name: "郑州宇华实验小学", icon: school_05 },
  { name: "郑州高新技术产业开发区长椿路小学", icon: school_06 },
  { name: "郑州市二七区兴华街第二小学", icon: school_07 },
  { name: "郑州中学附属小学", icon: school_08 },
  { name: "郑州高新区实验小学", icon: school_09 },
  { name: "郑州市互助路小学", icon: school_10 },
  { name: "郑州市陇西小学南校区", icon: school_11 },
  { name: "郑州市二七区兴华小学", icon: school_12 },
  { name: "郑州市管城区第二实验小学", icon: school_13 },
  { name: "郑州市第九十六中学", icon: school_14 },
  { name: "郑州中原一中实验学校小学部", icon: school_15 },
  { name: "郑州阳光雨露幸福树幼儿园", icon: school_16 },
  { name: "郑州师范学院附属小学", icon: school_17 },
  { name: "郑州市中原区阳光小学", icon: school_18 },
];
const LoveSchool = () => {
  return (
    <div className="tab-school-content">
      {schools.map((ele) => {
        return (
          <div key={ele.name} className="tab-item">
            <div className="logo">
              <img src={ele.icon} alt="" />
            </div>
            <div className="name">{ele.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default LoveSchool;
