(function (doc, win, designWidth, rem2Px) {
  function adapt(designWidth, rem2Px) {
    let d = window.document.createElement('div');
    d.style.width = '1rem';
    d.style.display = "none";
    let head = window.document.getElementsByTagName('head')[0];
    head.appendChild(d);
    return parseFloat(window.getComputedStyle(d, null).getPropertyValue('width'))
  }

  let docEl = doc.documentElement,
    defaultFontSize = adapt(designWidth, rem2Px),
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      let clientWidth = win.innerWidth
        || doc.documentElement.clientWidth
        || doc.body.clientWidth;

      if (!clientWidth) return;
      if (clientWidth < 750) {
        docEl.style.fontSize = clientWidth / designWidth * rem2Px / defaultFontSize * 100 + '%';

      } else {
        // docEl.style.fontSize = '625%';
      }
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window, 375, 37.5);
