import React from "react";
import './style.scss';
import isMobile from "ismobilejs";
import img_1 from '../../../../../assets/mobile/edu_img1@3x.png';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import img_2 from "../../../../../assets/images/edu_img2@2x.png";
import img_3 from "../../../../../assets/images/edu_img3@2x.png";
import img_4 from "../../../../../assets/images/edu_img4@2x.png";
import img_5 from "../../../../../assets/images/edu_img5@2x.png";
import SwiperCore, {Autoplay, Navigation} from "swiper";

SwiperCore.use([Autoplay, Navigation]);
const EducationalInnovationPage = () => {
  return <div className={'EducationalInnovationPage'}>
    <div className={'headTitle'}>均衡教育创新解决方案</div>
    <div className={'headInfo'}>聚焦国家教育均衡政策，运用互联网技术，解决城乡师资不均衡、图书资源不均衡、写作水平不均衡等问题。</div>
    {/*青于蓝图书馆*/}
    <div className={'qylLibrary'}>
      <div className={'head'}>
        <div className={'title'}>青于蓝云图书馆</div>
        <div className={'info'}>图书自助管理与借阅，实现城乡图书资源均衡</div>
      </div>
      <div className={'qylLibraryContent'}>
        <div className={'item'}>
          <div className={'top'}>
            <div className={'icon'}/>
            <div className={'inlineTitle'}>线上云管理</div>
          </div>
          <div className={'text'}>实现校园图书室自助借阅、自助归还，解决乡村学校缺乏专业图书管理难题。</div>
        </div>

        <div className={'item'}>
          <div className={'top'}>
            <div className={'icon'}/>
            <div className={'inlineTitle'}>阅读分享</div>
          </div>
          <div className={'text'}>实现学生之间的阅读在线分享交流，提升学生的阅读兴趣和收获。</div>
        </div>

        <div className={'item'}>
          <div className={'top'}>
            <div className={'icon'}/>
            <div className={'inlineTitle'}>家长监管</div>
          </div>
          <div className={'text'}>家长还能通过移动端，清晰地看到孩子的阅读相关数据。</div>
        </div>

        <div className={'item'}>
          <div className={'top'}>
            <div className={'icon'}/>
            <div className={'inlineTitle'}>科学分析</div>
          </div>
          <div className={'text'}>纵向、横向对比分析，科学计算学生阅读能力，并给予指导建议。</div>
        </div>
      </div>
    </div>
    {/*名师资源共享*/}
    <div className={'famousTeacherResources'}>
      <div className={'left'}>
        <div className={'title'}>名师资源共享</div>
        <div className={'info'}>实现城乡教师资源均衡，助力乡村教育振兴</div>
        {
          isMobile(window.navigator).phone ? <img src={img_1} alt=""/> : <div/>
        }
        <div className={'text'}>为适龄儿童提供均等的教育机会，保障教育过程，实现教育资源再分配，以缩小区域间、校际间的差距，最终推动全民素质教育的发展，为国家及社会发展创造人才。</div>
      </div>
      <div className={'right'}/>
    </div>
    {/*人工智能作文批改*/}
    {
      isMobile(window.navigator).phone ? <div className={'intelligentCompositionCorrectionMobile'}>
        <div className={'title'}>人工智能作文批改</div>
        <div className={'headTitle'}>中文作文24小时在线秒批，实现城乡写作水平均衡发展</div>
        <div className={'intelligentCommunicationContent'}>
          <div className={'intelligentCommunicationMobileSwiper'}>
            <Swiper
              autoplay={{delay: 2000, disableOnInteraction: false}}
              loop={true}
              updateOnWindowResize={true}
              resizeObserver={true}
              pagination={{clickable: true, el: '.intelligentCommunicationMobilePagination'}}
            >
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_2} alt=""/>
                  <div className={'bottom'}>
                    <h1>实时秒批</h1>
                    <p>学生通过手机上传作文后，立刻就能得到批改结果，有效提高学生写作兴趣。</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_3} alt=""/>
                  <div className={'bottom'}>
                    <h1>评价客观</h1> <p>相当于近1000位语文老师同时批改一篇作文，批改结果精准如同名师。</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_4} alt=""/>
                  <div className={'bottom'}>
                    <h1>评价全面</h1> <p>不仅包含全文总评、段落评价、精彩点评，还会对相关知识点进行拓展和讲解。</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_5} alt=""/>
                  <div className={'bottom'}>
                    <h1>写作提升</h1> <p>自动校对病句、错别字和错误标点，并给出明确的修改建议，有效提升写作水平。</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={'intelligentCommunicationMobilePagination'}/>
        </div>
      </div> : <div className={'intelligentCompositionCorrection'}>
        <div className="title">人工智能作文批改</div>
        <div className="headInfo">中文作文24小时在线秒批，实现城乡写作水平均衡发展</div>
        <div className="intelligentCompositionCorrectionContent">
          <div className={'item'}>
            实时秒批
            <div className={'hover'}>
              <h1>实时秒批</h1>
              <p>学生通过手机上传作文后，立刻就能得到批改结果，有效提高学生写作兴趣。</p>
            </div>
          </div>
          <div className={'item'}>评价客观
            <div className={'hover'}>
              <h1>评价客观</h1>
              <p>相当于近1000位语文老师同时批改一篇作文，批改结果精准如同名师。</p>
            </div>
          </div>
          <div className={'item'}>评价全面
            <div className={'hover'}>
              <h1>评价全面</h1>
              <p>不仅包含全文总评、段落评价、精彩点评，还会对相关知识点进行拓展和讲解。</p>
            </div>
          </div>
          <div className={'item'}>写作提升
            <div className={'hover'}>
              <h1>写作提升</h1>
              <p>自动校对病句、错别字和错误标点，并给出明确的修改建议，有效提升写作水平。</p>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
}
export default EducationalInnovationPage;
