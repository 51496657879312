import "core-js";
import "./utils/adaptation";
import React from "react";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./app.scss";
import "rc-pagination/assets/index.css";
import App from "./app";

const rootElement = document.getElementById("root");

render(<App />, rootElement);

reportWebVitals();
