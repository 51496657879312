import React, {useState} from "react";
import './style.scss';
import isMobile from "ismobilejs";
import img_1 from '../../../../../assets/mobile/finance_img1@3x.png';
import img_2 from '../../../../../assets/mobile/finance_img2@3x.png';

const DigitalFinancePage = () => {
  const [active, setActive] = useState(0);
  return <div className={'DigitalFinancePage'}>
    <div className={'headTitle'}>数字化金融解决方案</div>
    <div className={'headInfo'}>为用户提供更加贴近家庭消费习惯和体验的一站式数字化金融服务，探索家庭数据资产管理运营和个性化服务模式。</div>
    {
      isMobile(window.navigator).phone ? <div className={'DigitalFinancePageContentMobile'}>

        <div className={'item'}>
          <img src={img_1} alt=""/>
          <div className={'title'}>家庭数字资产</div>
          <div
            className={'info'}>从个人行为数据的底稿记录，形成数字资产并赋予价值，通过授权行为形成流通形成商品化数字资产包,通过整体生态的资源匹配对数字资产进行有效配置，根据资源方的需求赋予数字资产价值进行价值化封装。
          </div>
        </div>

        <div className={'item'}>
          <img src={img_2} alt=""/>
          <div className={'title'}>数字资产理财</div>
          <div
            className={'info'}>利用区块链技术突破传统理财模式限制，将数字资产纳入到基金理财的范畴，通过为手中的数字资产购买理财产品，以此来规避一些ICO项目带来的风险。
          </div>
        </div>
      </div> : <div className={'DigitalFinancePageContent'}>
        <div className={'digitalFinancePageBanner'}>
          <div className={`bannerItem ${active === 0 && 'active'}`}/>
          <div className={`bannerItem ${active === 1 && 'active'}`}/>
        </div>
        <div className={'infoContent'}>
          <div className={`item ${active === 0 && 'active'}`} onMouseEnter={() => setActive(0)}>
            <div className={'title'}>家庭数字资产</div>
            <div
              className={'info'}>从个人行为数据的底稿记录，形成数字资产并赋予价值，通过授权行为形成流通形成商品化数字资产包,通过整体生态的资源匹配对数字资产进行有效配置，根据资源方的需求赋予数字资产价值进行价值化封装。
            </div>
          </div>
          <div className={`item ${active === 1 && 'active'}`} onMouseEnter={() => setActive(1)}>
            <div className={'title'}>数字资产理财</div>
            <div className={'info'}>利用区块链技术突破传统理财模式限制，将数字资产纳入到基金理财的范畴，通过为手中的数字资产购买理财产品，以此来规避一些ICO项目带来的风险。</div>
          </div>
        </div>
      </div>
    }
  </div>
}
export default DigitalFinancePage;
