import React from "react";
import './style.scss';
import DigitalCampusCenter from "../../../components/digitalCampusCenter";
import CustomerCase from "../../../components/customerCase";
import isMobile from "ismobilejs";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import SwiperCore, {Autoplay, Navigation} from 'swiper';
import img_1 from '../../../../../assets/images/school_img05@2x.png'
import img_2 from '../../../../../assets/images/school_img06@2x.png'
import img_3 from '../../../../../assets/images/school_img07@2x.png'
import img_4 from '../../../../../assets/images/school_img08@2x.png'

import img_5 from '../../../../../assets/mobile/school_img09@3x.png'
import img_6 from '../../../../../assets/mobile/school_img10@3x.png'
import img_7 from '../../../../../assets/mobile/school_img11@3x.png'
import img_8 from '../../../../../assets/mobile/school_img12@3x.png'

SwiperCore.use([Autoplay, Navigation]);
const SolutionIndexPage = () => {
  return <div className={'SolutionIndexPage'}>
    {/*数字化校园服务中心*/}
    <DigitalCampusCenter/>
    {/*校园支付*/}
    <div className={'campusPayment'}>
      <div className={'title'}>校园支付</div>
      <div className={'headTitle'}>告别校园现金交易烦恼</div>
      {
        isMobile(window.navigator).phone ? <div className={'tip'}>（左右滑动浏览）</div> : <div/>
      }
      <div className={'campusPaymentContent'}>
        <div className={'item'}>家 长
          <div className={'hover'}>
            <p>
              <span>手机缴学费{isMobile(window.navigator).phone ? '' : <br/>}</span>
              <span>在线充生活费<br/></span>
            </p>
            <p>
              <span>孩子消费监管{isMobile(window.navigator).phone ? '' : <br/>}</span>
              <span>异常消费报警</span>
            </p>
          </div>
        </div>
        <div className={'item'}>学 生
          <div className={'hover'}>
            <p>
              <span>刷卡消费{isMobile(window.navigator).phone ? '' : <br/>}</span>
              <span>刷脸消费</span>
            </p>
            <p>
              <span>丰富消费场景</span>
            </p>
          </div>
        </div>
        <div className={'item'}>
          支付
          <div className={'hover'}>
            <p>
              <span>薪资发放{isMobile(window.navigator).phone ? '' : <br/>}</span>
              <span>补助发放</span>
            </p>
            <p>
              <span>对账查账</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    {/*智能通讯*/}
    {
      isMobile(window.navigator).phone ? <div className={'intelligentCommunicationMobile'}>
        <div className={'title'}>智能通讯</div>
        <div className={'headTitle'}>满足学生在校与家长通话需求</div>
        <div className={'intelligentCommunicationContent'}>
          <div className={'intelligentCommunicationMobileSwiper'}>
            <Swiper
              autoplay={{delay: 2000, disableOnInteraction: false}}
              loop={true}
              updateOnWindowResize={true}
              resizeObserver={true}
              pagination={{clickable: true, el: '.intelligentCommunicationMobilePagination'}}
            >
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_1} alt=""/>
                  <div className={'bottom'}>
                    <h1>拨打方式</h1>
                    <p>学生刷脸或刷卡即可与家长沟通</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_2} alt=""/>
                  <div className={'bottom'}>
                    <h1>号码可控</h1> <p>学生在校可拨打的号码由家长设定</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_3} alt=""/>
                  <div className={'bottom'}>
                    <h1>多种方式</h1> <p>语音通话+视频通话，满足不同需求</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_4} alt=""/>
                  <div className={'bottom'}>
                    <h1>免受打扰</h1> <p>只可呼出、不可呼入，上课免受打扰</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={'intelligentCommunicationMobilePagination'}/>
        </div>
      </div> : <div className={'intelligentCommunication'}>
        <div className={'title'}>智能通讯</div>
        <div className={'headTitle'}>满足学生在校与家长通话需求</div>
        <div className={'intelligentCommunicationContent'}>
          <div className={'item'}>拨打方式
            <div className={'hover'}>
              <h1>拨打方式</h1> <p>学生刷脸或刷卡即可与家长沟通</p>
            </div>
          </div>
          <div className={'item'}>号码可控
            <div className={'hover'}>
              <h1>号码可控</h1> <p>学生在校可拨打的号码由家长设定</p>
            </div>
          </div>
          <div className={'item'}>多种方式
            <div className={'hover'}>
              <h1>多种方式</h1> <p>语音通话+视频通话，满足不同需求</p>
            </div>
          </div>
          <div className={'item'}>
            免受打扰
            <div className={'hover'}>
              <h1>免受打扰</h1> <p>只可呼出、不可呼入，上课免受打扰</p>
            </div>
          </div>
        </div>
      </div>
    }


    {/*智能物流*/}
    <div className={'intelligentLogistics'}>
      <div className="title">智慧物流</div>
      <div className="headTitle">解决中小学生校园包裹最后100米</div>
      <div className={'intelligentLogisticsContent'}>
        <div className={'item'}>
          {
            isMobile(window.navigator).phone ? <div className={'icon'}>
              <img src={img_5} alt=""/>
            </div> : <div/>
          }
          <div className={'right'}>
            <div className={'contentTitle'}>扫码存件</div>
            <div className={'text'}>家长通过公众号扫码操作，即存即走、无需等待</div>
          </div>
        </div>
        <div className={'item'}>
          {
            isMobile(window.navigator).phone ? <div className={'icon'}>
              <img src={img_6} alt=""/>
            </div> : <div/>
          }
          <div className={'right'}>
            <div className={'contentTitle'}>精准通知</div>
            <div className={'text'}>取件信息准确通知到个人，减轻老师工作负担</div>
          </div>
        </div>
        <div className={'item'}>
          {
            isMobile(window.navigator).phone ? <div className={'icon'}>
              <img src={img_7} alt=""/>
            </div> : <div/>
          }
          <div className={'right'}>
            <div className={'contentTitle'}>刷脸取件</div>
            <div className={'text'}>取件信息准确通知到个人，减轻老师工作负担</div>
          </div>
        </div>
        <div className={'item'}>
          {
            isMobile(window.navigator).phone ? <div className={'icon'}>
              <img src={img_8} alt=""/>
            </div> : <div/>
          }
          <div className={'right'}>
            <div className={'contentTitle'}>存放有序</div>
            <div className={'text'}>学生包裹存放井然有序_提升校园形象</div>
          </div>
        </div>
      </div>
    </div>

    {/*客户案例*/}
    <CustomerCase/>
  </div>
}
export default SolutionIndexPage;
