import React from "react";
import "./style.scss";
import iconSubscribeQr from "../../../../assets/images/dingyue.png";
import iconPolice from "../../../../assets/icon/police.png";
// import iconServiceQr from '../../../../assets/images/Qr_code02@2x.jpg';
// import iconDownloadQr from '../../../../assets/images/Qr_code03@2x.jpg';

const Footer = () => {
  return (
    <div className={"footerComponent"}>
      <div className={"content footerContent"}>
        <div className={"list"}>
          <div className={"item"}>
            <div className={"qr"}>
              <img
                src={
                  window.GLOBAL_WEB_INFO.wx_image
                    ? `${process.env["ASSETS_URL"]}${window.GLOBAL_WEB_INFO.wx_image}`
                    : iconSubscribeQr
                }
                alt=""
              />
            </div>
            <div className={"text"}>官方公众号</div>
          </div>
          {/*<div className={'item'}>*/}
          {/*  <div className={'qr'}><img src={iconServiceQr} alt=""/></div>*/}
          {/*  <div className={'text'}>青于蓝家长学院</div>*/}
          {/*</div>*/}
          {/*<div className={'item'}>*/}
          {/*  <div className={'qr'}><img src={iconDownloadQr} alt=""/></div>*/}
          {/*  <div className={'text'}>APP下载</div>*/}
          {/*</div>*/}
        </div>
        <div className={"keepOnRecord"}>
          © {new Date().getFullYear()}｜
          <a href={`tel:${window.GLOBAL_WEB_INFO.tel && "400-096-7111"}`}>
            {window.GLOBAL_WEB_INFO.tel || "400-096-7111"}
          </a>
          ｜
          <a href="https://beian.miit.gov.cn/">
            {window.GLOBAL_WEB_INFO.name || "北京家音顺达数据技术有限公司"}｜
            {window.GLOBAL_WEB_INFO.record_no || "京ICP备20013441号"}
          </a>
          ｜
          <a
            href={`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${
              window.GLOBAL_WEB_INFO.police_record_no || "11011602000844"
            }`}
          >
            <img src={iconPolice} className={"iconPolice"} alt={"公安备案号"} />
            公安备案号：
            {window.GLOBAL_WEB_INFO.police_record_no || "11011602000844"}
          </a>
          {/*｜<a href="https://app.eduyun.cn/">教APP备4100032号</a>*/}
        </div>
      </div>
    </div>
  );
};
export default Footer;
