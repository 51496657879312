const DonatedSchool = () => {
  return (
    <div className="tab-donated-content">
      <div className="column">
        <div className="column-card">
          <div className="title-box">河南希望工程捐赠名单</div>
          <div className="card-content">
            <div>新密市白寨镇史沟希望小学</div>
            <div>南阳市西峡县桑坪镇正弘希望小学</div>
            <div>南阳市西峡县海尔希望小学</div>
            <div>中牟县姚家镇土山店希望小学</div>
            <div>洛阳新安县正村镇正村交通希望小学</div>
          </div>
        </div>
        <div className="column-card">
          <div className="title-box">河南720受灾捐赠名单</div>
          <div className="card-content">
            <div>新乡市卫辉市唐岗完全小学</div>
            <div>新乡市辉县市占城镇马张莫小学</div>
            <div>新乡市辉县市占城镇陶村小学</div>
            <div>新乡市辉县市占城镇王庄小学</div>
            <div>新乡市辉县市占城镇南小营小学</div>
            <div>新乡市辉县市占城镇三位营小学</div>
            <div>新乡市辉县市占城镇王官营小学</div>
            <div>新乡市辉县市占城镇西凡村小学</div>
            <div>新乡市辉县市占城镇中心学校</div>
            <div>新乡市辉县市占城镇大占城小学</div>
            <div>新乡市辉县市占城镇北马营小学</div>
            <div>新乡市辉县市占城镇师庄小学</div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="column-card">
          <div className="title-box">河南驻村第一书记捐赠名单</div>
          <div className="card-content">
            <div>南阳市方城县杨楼镇邵岗小学</div>
            <div>南阳市南召县城郊乡竹园沟小学</div>
            <div>周口市扶沟县大李庄乡冯老小学</div>
            <div>周口市西华县艾岗乡半截楼村小学</div>
            <div>周口市西华县红花镇龙池头小学</div>
            <div>南阳市南召县太山庙中心小学</div>
            <div>邓州市龙堰乡刁河回族小学</div>
            <div>南阳市内乡县瓦亭镇中心小学</div>
            <div>南阳市内乡县曹营小学</div>
            <div>内乡县杨沟小学</div>
            <div>驻马店市上蔡县高李村高李小学</div>
            <div>驻马店市汝南县臻头河小学</div>
            <div>驻马店市上蔡县孟楼小学</div>
            <div>河南省南阳市淅川县九重镇武店村</div>
            <div>南阳市淅川县九重镇邹庄村</div>
            <div>南阳市淅川县丹阳镇下孔村</div>
            <div>南阳市桐柏县新集乡磨沟村</div>
            <div>南阳市内乡县曹营村村部</div>
            <div>濮阳县郎中乡马白邱小学</div>
            <div>舞阳县侯集镇余庄小学</div>
            <div>灵宝市寺河乡实验学校</div>
            <div>封丘县应举镇东大村小学</div>
            <div>平顶山市鲁山县赵村镇第一小学</div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="column-card">
          <div className="title-box">全国捐助计划捐赠名单</div>
          <div className="card-content">
            <div>巴楚县阿纳库勒乡第四小学</div>
            <div>巴楚县阿纳库勒乡第七小学</div>
            <div>甘肃省东乡族自治县柳树乡马百户小学</div>
            <div>甘肃省陇南礼县雷坝镇中心小学</div>
            <div>甘肃省康乐县周家沟小学</div>
          </div>
        </div>
        <div className="column-card">
          <div className="title-box">其他捐赠名单</div>
          <div className="card-content">
            <div>新乡市卫辉市上乐村镇宋村完全小学</div>
            <div>巩义市西村镇圣水村第八小学</div>
            <div>洛阳新安县石井镇中心小学</div>
            <div>郑州市荥阳市刘河镇中心小学</div>
            <div>洛阳市孟津区白鹤镇中心小学</div>
            <div>洛阳市栾川县冷水河镇中心小学</div>
            <div>新郑市梨河镇梨河完全小学</div>
            <div>河南省扶沟县固城乡初级中学</div>
            <div>登封市卢店镇卢南小学</div>
            <div>登封市东华镇第二小学</div>
            <div>永城市龙港镇孟李楼小学</div>
            <div>永城市邙山镇僖山小学</div>
            <div>河南省焦作市武陟县嘉应观乡二铺营村</div>
            <div>中国工农红军河南社旗革命英烈红军小学</div>
            <div>新郑市八千乡沙张小学</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DonatedSchool;
