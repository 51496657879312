import Axios from "axios";

// Axios.defaults.baseURL = process.env.BASE_URL;

Axios.defaults.timeout = 10000;
// 添加请求拦截器
Axios.interceptors.request.use(
  (config) => {
    return config;
  },
  function (error) {
    return { code: 0, msg: "网络异常请稍后重试", data: null };
    // throw new Error(error);
  }
);
// 添加响应拦截器
Axios.interceptors.response.use(
  (response) => {
    return {
      data: response.data,
      code: response.status,
      msg: response.data.msg || response.data.message || response.statusText,
      headers: response.headers,
    };
  },
  function (error) {
    try {
      let data = error.response.data;
      return {
        code: data.code || 0,
        msg: data.message || "网络异常请稍后重试",
        data: null,
      };
    } catch (e) {
      return { code: 0, msg: "网络异常请稍后重试", data: null };
    }
    // throw new Error(error);
  }
);
export default Axios;
