import React from "react";
import "./index.scss";
import "./indexMobile.scss";
import hundred_icon from "../../../../../../assets/solution/100.png";
import all_icon from "../../../../../../assets/solution/all.png";
import hlht_icon from "../../../../../../assets/solution/hlht.png";
import ksz_icon from "../../../../../../assets/solution/ksz.png";
import _ from "lodash";
import isMobile from "ismobilejs";

const data = [
  { icon: hundred_icon, title: "100+", desc: "预集成应用，开箱即用" },
  { icon: all_icon, title: "All in one", desc: "物联设备在线化" },
  { icon: hlht_icon, title: "数据互联互通", desc: "开放被集成" },
  { icon: ksz_icon, title: "可生长", desc: "无缝衔接海量第三方应用、硬件" },
];
export default function HundredAdd() {
  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "hundred_container_mobile"
          : "hundred_container"
      }
    >
      <div
        style={
          isMobile(window.navigator).phone
            ? {
                display: "flex",
                // width: 375,

                justifyContent: "space-between",
                flexDirection: "row",
                flexWrap: "wrap",
              }
            : {
                display: "flex",
                width: 1440,
                justifyContent: "space-between",
              }
        }
      >
        {_.map(data, (item, i) => {
          return (
            <div className="hundred_item" key={i}>
              <img className="hundred_item_icon" src={item.icon} />
              <div className="hundred_item_right">
                <div className="hundred_item_title">{item.title}</div>
                <div className="hundred_item_desc">{item.desc}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
