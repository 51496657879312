import React from "react";
import './style.scss';

import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import SwiperCore, {Autoplay, Navigation} from 'swiper';


import s_1 from "../../../../assets/images/school_cr_icon1@2x.png";
import s_2 from '../../../../assets/images/school_cr_icon2@2x.png';
import s_3 from '../../../../assets/images/school_cr_icon3@2x.png';
import s_4 from '../../../../assets/images/school_cr_icon4@2x.png';
import s_5 from '../../../../assets/images/school_cr_icon5@2x.png';
import s_6 from '../../../../assets/images/school_cr_icon6@2x.png';
import s_7 from '../../../../assets/images/school_cr_icon7@2x.png';

import kt_1 from '../../../../assets/images/school_kt_icon1@2x.png';
import kt_2 from '../../../../assets/images/school_kt_icon2@2x.png';
import kt_3 from '../../../../assets/images/school_kt_icon3@2x.png';
import kt_4 from '../../../../assets/images/school_kt_icon4@2x.png';
import kt_5 from '../../../../assets/images/school_kt_icon5@2x.png';

import zc_1 from '../../../../assets/images/school_zc_icon1@2x.png';
import zc_2 from '../../../../assets/images/school_zc_icon2@2x.png';
import zc_3 from '../../../../assets/images/school_zc_icon3@2x.png';
import zc_4 from '../../../../assets/images/school_zc_icon4@2x.png';

import xx_1 from '../../../../assets/images/school_xx_icon1@2x.png';
import xx_2 from '../../../../assets/images/school_xx_icon2@2x.png';
import xx_3 from '../../../../assets/images/school_xx_icon3@2x.png';
import xx_4 from '../../../../assets/images/school_xx_icon4@2x.png';
import xx_5 from '../../../../assets/images/school_xx_icon5@2x.png';
import isMobile from "ismobilejs";

SwiperCore.use([Autoplay, Navigation]);
const DigitalCampusCenter = () => {

  return <div className={'DigitalCampusCenter'}>
    <div className={'title'}>数字化校园解决方案</div>
    <div className={'des'}>
      结合北斗网格码、区块链、行为分析、人脸识别、大数据等技术，研发整套数字化校园创新解决方案，满足家庭对孩子在校园的数字化需求，创建更便捷、更安全、更智能的数字化校园环境，累计合作学校4000余所。
    </div>
    <div className="schoolSecurity">
      <div className={'left'}>
        <div className={'title'}>校园安全</div>
        <div className={'info'}>数字化校园安全管理{isMobile(window.navigator).phone ? '' : <br/>}创新解决办法</div>
      </div>
      <div className="right">
        <div className={'top digitalCampusCenterSwiperPagination'}/>
        <div className={`bottom`}>
          <Swiper
            autoplay={{delay: 2000, disableOnInteraction: false}}
            loop={true}
            updateOnWindowResize={true}
            resizeObserver={true}
            allowTouchMove={false}
            className={'digitalCampusCenterSwiper'}
            pagination={{clickable: true, el: '.digitalCampusCenterSwiperPagination'}}

          >
            <SwiperSlide>
              <div className={`itemContent`}>

                <div className={'item'}>
                  <div className="icon"><img src={s_1} alt=""/></div>
                  <div className="headTitle">人脸识别</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={s_2} alt=""/></div>
                  <div className="headTitle">精准校验</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={s_3} alt=""/></div>
                  <div className="headTitle">云考勤</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={s_4} alt=""/></div>
                  <div className="headTitle">访客预约</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={s_5} alt=""/></div>
                  <div className="headTitle">在线请假</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={s_6} alt=""/></div>
                  <div className="headTitle">分时进出校</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={s_7} alt=""/></div>
                  <div className="headTitle">人流量分析</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`itemContent`}>

                <div className={'item'}>
                  <div className="icon"><img src={kt_1} alt=""/></div>
                  <div className="headTitle">班级点名</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={kt_2} alt=""/></div>
                  <div className="headTitle">教学测评</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={kt_3} alt=""/></div>
                  <div className="headTitle small">教师行为分析</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={kt_4} alt=""/></div>
                  <div className="headTitle small">学生行为分析</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={kt_5} alt=""/></div>
                  <div className="headTitle">走班排课</div>
                </div>

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`itemContent`}>

                <div className={'item'}>
                  <div className="icon"><img src={zc_1} alt=""/></div>
                  <div className="headTitle">三维编码</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={zc_2} alt=""/></div>
                  <div className="headTitle">网格化盘点</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={zc_3} alt=""/></div>
                  <div className="headTitle">视频值班</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={zc_4} alt=""/></div>
                  <div className="headTitle">自动预警</div>
                </div>

              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={`itemContent`}>

                <div className={'item'}>
                  <div className="icon"><img src={xx_1} alt=""/></div>
                  <div className="headTitle small">教育信息上链</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={xx_2} alt=""/></div>
                  <div className="headTitle small">用户身份认证</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={xx_3} alt=""/></div>
                  <div className="headTitle small">知识产权确权</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={xx_4} alt=""/></div>
                  <div className="headTitle">信息跨链</div>
                </div>

                <div className={'item'}>
                  <div className="icon"><img src={xx_5} alt=""/></div>
                  <div className="headTitle">权益保障</div>
                </div>

              </div>
            </SwiperSlide>
          </Swiper>


        </div>
      </div>
    </div>
  </div>
}
export default DigitalCampusCenter;
