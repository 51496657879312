import React from "react";
import "./style.scss";

const WalkingLantern = (
  props = {
    data: [],
    itemStyle: {},
    animation: "loop infinite linear 15s",
  }
) => {
  return (
    <div className={"WalkingLantern"}>
      <div className={"aniContent"} style={{ animation: props.animation }}>
        {props.data.map((item, index) => (
          <div
            className="item"
            style={props.itemStyle}
            key={`WalkingLantern_${index}`}
          >
            <img src={item} alt="" className="src" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default WalkingLantern;
