import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import store, { COOPERATION } from "../../../../utils/store";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { useMounted } from "../../../../utils/hook";
import isMobile from "ismobilejs";

SwiperCore.use([Autoplay, Navigation]);

const AchievementSwiper = () => {
  const isMounted = useMounted();
  const [list, setList] = useState([]);
  const [active, setActive] = useState(1);

  const swiperRef = useRef(null);

  useEffect(() => {
    const storeList = store.getArray(COOPERATION);
    setList(storeList);
    setActive(storeList.length - 2);
    const getData = async () => {
      const res = await Axios({
        url: Apis.cooperation,
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      store.setArray(COOPERATION, data);
      setList(data);
      setActive(data.length - 2);
    };

    (async () => {
      await getData();
    })();
  }, []);

  const change = (index) => {
    setActive(index + 1 === list.length ? 0 : index + 1);
  };

  const swiperGo = (index) => {
    swiperRef.current.swiper.slideTo(index + 2, 0);
  };

  return (
    <div
      className={` ${
        isMobile(window.navigator).phone
          ? "AchievementSwiperMobile"
          : "AchievementSwiper"
      }`}
    >
      <div className={"swiper"}>
        <div className={"leftButton"} />
        <div className={"swiperContent"}>
          <div className={"swiperContentBox"}>
            {isMobile(window.navigator).phone ? (
              <Swiper
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                slidesPerView={1}
                loop={true}
                updateOnWindowResize={true}
                resizeObserver={true}
                className={"AchievementSwiper_Box_mobile"}
                onRealIndexChange={(item) => change(item.realIndex)}
              >
                {list.map((item, index) => (
                  <SwiperSlide
                    className={"AchievementSwiper_SwiperSlide"}
                    key={`AchievementSwiper_${index}`}
                  >
                    <div className={`item ${index === active && "active"}`}>
                      <div className={"bgColor"} />
                      <div className={"bg"} />
                      <div className={"itemContent"}>
                        <div className={"icon"}>
                          <img
                            src={`${process.env["ASSETS_URL"]}${item.icon_active}`}
                            alt=""
                          />
                        </div>
                        <pre className={"itemName"}>{item.title}</pre>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Swiper
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                ref={swiperRef}
                slidesPerView={isMobile(window.navigator).phone ? 1 : 3}
                loop={true}
                navigation={{ nextEl: ".rightButton", prevEl: ".leftButton" }}
                className={"AchievementSwiper_Box"}
                onRealIndexChange={(item) => change(item.realIndex)}
              >
                {list.map((item, index) => (
                  <SwiperSlide
                    zoom={true}
                    virtualIndex={index}
                    className={"AchievementSwiper_SwiperSlide"}
                    key={`AchievementSwiper_${index}`}
                    onClick={() => swiperGo(index)}
                  >
                    <div className={`item ${index === active && "active"}`}>
                      <div className={"bgColor"} />
                      <div className={"bg"} />
                      <div className={"itemContent"}>
                        <div className={"icon"}>
                          <img
                            src={
                              index === active
                                ? `${process.env["ASSETS_URL"]}${item.icon_active}`
                                : `${process.env["ASSETS_URL"]}${item.icon}`
                            }
                            alt=""
                          />
                        </div>
                        <pre className={"itemName"}>{item.title}</pre>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
        <div className={"rightButton"} />
      </div>
      {list[active] && (
        <div className={"infoBox"}>
          <div className={"arrow"} />
          <div className={"box"}>
            <div className={"img"}>
              <img
                src={`${process.env["ASSETS_URL"]}${list[active].main_image}`}
                alt=""
              />
            </div>
            <div className={"info"}>
              <div className={"name"}>
                <pre>{list[active].title}</pre>
              </div>
              <div className={"text"}>{list[active].description}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AchievementSwiper;
