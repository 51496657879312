import React from "react";
import "./style.scss";
import defaultIcon from "../../../../assets/images/top_bg1@2x.png";
import isMobile from "ismobilejs";

const TopBanner = (props = { english: "", icon: defaultIcon, title: "" }) => {
  const isPhone = isMobile(window.navigator).phone;
  return (
    <div className={"TopBanner"} style={{ height: isPhone ? 220 : 600 }}>
      <img
        src={props.icon}
        alt=""
        style={{ width: "100%", height: isPhone ? 220 : 600 }}
      />
      <div className={"bannerContent"}>
        <div className={"content"}>
          <div className={isPhone ? "titleEnglish_mobile" : "titleEnglish"}>
            {props.english}
          </div>
          <div className={isPhone ? "line_mobile" : "line"} />
          <div className={isPhone ? "title_mobile" : "title"}>
            {props.title}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopBanner;
