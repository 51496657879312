import React from "react";
import isMobile from "ismobilejs";
import icon_2 from "../../../../../../assets/mobile/top_bg3@3x.png";
import icon from "../../../../../../assets/images/top_bg3@2x.png";
import TopBanner from "../../../../components/topBanner";
import BreadcrumbNavigation from "../../../../components/breadcrumbNavigation";
import {Routes} from "../../../../../../utils/routes";
import {useHistory} from 'react-router-dom';
import './style.scss';

const SolutionBigDataBusinessCabinet = () => {
  const history = useHistory();
  return <div className={'SolutionBigDataBusinessCabinet SolutionBigDataChild'}>
    <TopBanner icon={isMobile(window.navigator).phone ? icon_2 : icon} title={'解决方案'} english={'WELFARE'}/>
    <BreadcrumbNavigation data={[
      {title: '首页', click: () => history.push(Routes.home)},
      {title: '公共大数据', click: () => history.go(-1)},
      {title: '公务柜'},
    ]}/>
    <div className={'headTitle'}>无接触智能授权公务投递系统</div>
    <div className={'headLabel'}>畅通材料交转的“最后100米”</div>
    <div className={'productBackground'}>
      <div className={'img'}/>
      <div className={'info'}>
        <div className={'title'}>产品背景</div>
        <div className={'msg'}>
          1、疫情常态化防控形势下，无接触逐渐成为各个机关单位防控疫情的新要求。<br/>
          2、法官因工作需要，需频繁接收律师、当事人等外来人员呈递的各种文件，很难真正做到无接触。<br/>
          3、当事人与法官流转材料时，常出现程序繁琐、交接不畅、耗时耗力等情况，大大增加了时间成本。
        </div>
      </div>
    </div>
    <div className={'productIntroduction'}>
      <div className={'content'}>
        <h1>系统介绍</h1>
        <p>无接触智能授权公务投递系统，专为法院接收诉讼材料场景设计，为当事人与法官提供零接触、授权式诉讼材料投取服务，避免当事人长时间等待，减少了当事人的诉累，提高法官工作效率，畅通到法院递交材料的“最后一百米”。</p>
      </div>
    </div>

    <div className={'productFeatures'}>
      <div className={'title'}>产品特点</div>
      <div className={'content'}>
        <div className={'item'}>
          智能授权
          <div className={'hover'}>
            <h1>智能授权</h1>
            <p>所有需要给法官递交诉讼材料、物证等物品的当事人（或律师），取得法官授权后，方能将相关物品放进投递柜内。</p>
          </div>
        </div>
        <div className={'item'}>
          无接触投取
          <div className={'hover'}>
            <h1>无接触投取</h1>
            <p>投递柜采用双向设计，一侧面向当事人，当事人无需进入法院就能递交材料；另一侧面向法官，法官不用离开法院，就能随时取出相应材料。</p>
          </div>
        </div>
        <div className={'item'}>
          跟踪查询
          <div className={'hover'}>
            <h1>跟踪查询</h1>
            <p>当事人可通过系统查询材料是否被提取、何时被提取，避免当事人与法官反复沟通确认，提高案件办理效率。</p>
          </div>
        </div>

        <div className={'item'}>
          独立部署+云端部署
          <div className={'hover'}>
            <h1>独立部署+云端部署</h1>
            <p>可根据法院已有网络系统进行独立部署（APP部署或公众号部署），也可采用云端部署，确保数据安全，方便使用。</p>
          </div>
        </div>

      </div>
    </div>
  </div>
}
export default SolutionBigDataBusinessCabinet;
