import React, { useEffect, useState } from "react";
import './style.scss';
import RecruitmentInformation from "../../../components/recruitmentInformation";
import store, { JOBS, JOBS_MENUS } from "../../../../../utils/store";
import Axios from "../../../../../utils/axios";
import Apis from "../../../../../utils/apis";
import { Routes } from "../../../../../utils/routes";
import { useMounted } from "../../../../../utils/hook";

const JoinUsIndexPage = (props) => {

	const [activeList, setActiveList] = useState([]);
	const isMounted = useMounted();

	useEffect(() => {
		const setData = () => {
			const list = store.getArray(JOBS);
			let currentList = [];
			list.forEach((x) => {
				if (x.id === props.match.params.type || props.match.params.type === '0') {
					if(x.list){
						x.list.forEach((y = {}) => {
							currentList.push({
								...y, site: y.city, typeName: x.name, desc: y.description, requirement: y.requirement,
							});
						})
					}
				}
			})
			setActiveList(currentList);
		}
		const getList = async () => {
			const res = await Axios({
				url: Apis.jobs
			})
			const { code = 200, data = [] } = res;
			if (code !== 200 || !isMounted) return false;
			const menus = data.map((item, index) => ({
				title: item.name, path: `${Routes.joinUsIndex}/${item.id}`
			}));
			store.setArray(JOBS, data);
			store.setArray(JOBS_MENUS, menus);
			setData();
		}

		(async () => {
			setData();
			await getList();
		})();

	}, [props.match.params.type]);


	return <div className={'JoinUsIndexPage'}>
		{activeList.map((item, index) => <RecruitmentInformation key={`JoinUsIndexPage_item_${index}`} data={item}/>)}
	</div>
}
export default JoinUsIndexPage;
