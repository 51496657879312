import React, { useEffect, useRef } from "react";
import "./index.scss";
import "./indexMobile.scss";
import ChinaJson from "../../../../../../utils/china.json";
import EChartsReact from "echarts-for-react";
import * as echarts from "echarts";
import _ from "lodash";
import isMobile from "ismobilejs";

const dataList = [
  { name: "河南", value: 2 },
  { name: "河北", value: 2 },
  { name: "北京", value: 2 },
  { name: "山东", value: 2 },
  { name: "上海", value: 2 },
  { name: "四川", value: 2 },
  { name: "新疆", value: 2 },
  { name: "青海", value: 1 },
  { name: "甘肃", value: 2 },
  { name: "陕西", value: 2 },
  { name: "山西", value: 2 },
  { name: "湖北", value: 1 },
  { name: "重庆", value: 1 },
  { name: "安徽", value: 2 },
  { name: "江苏", value: 1 },
  { name: "贵州", value: 1 },
  { name: "云南", value: 1 },
  { name: "天津", value: 1 },
  { name: "内蒙古", value: 0 },
  { name: "黑龙江", value: 0 },
  { name: "吉林", value: 0 },
  { name: "辽宁", value: 0 },
  { name: "宁夏", value: 0 },
  { name: "西藏", value: 0 },
  { name: "湖南", value: 0 },
  { name: "江西", value: 0 },
  { name: "浙江", value: 0 },
  { name: "广西", value: 0 },
  { name: "广东", value: 0 },
  { name: "福建", value: 0 },
  { name: "澳门", value: 0 },
  { name: "香港", value: 0 },
  { name: "台湾", value: 0 },
  { name: "海南", value: 0 },
  { name: "南海诸岛", value: 0 },
];

const piecesList = [
  { value: 2, label: "已覆盖", color: "#619EDF" },
  { value: 1, label: "即将覆盖", color: "#9ABCE8" },
  { value: 0, label: "战略规划中", color: "#DFEAFA" },
];

export default function BusinessScale() {
  echarts.registerMap("china", { geoJSON: ChinaJson });

  const dealRegions = () => {
    const filterList = _.filter(
      dataList,
      (item) => item.value === 1 || item.value === 0
    );
    const modifyRegions = _.map(filterList, (e) => {
      return {
        name: e.name,
        label: {
          emphasis: {
            show: true,
            fontSize: "12",
            color: "#333333",
            fontWeight: 600,
            fontFamily: "PingFangSC, PingFang SC",
          },
        },
        itemStyle: {
          emphasis: {
            areaColor: _.filter(piecesList, (item) => item.value === e.value)[0]
              .color,
          },
        },
      };
    });
    return modifyRegions;
  };

  const option = {
    visualMap: {
      type: "piecewise",
      min: 0,
      max: 2,
      itemWidth: isMobile(window.navigator).phone ? 40 : 50,
      itemHeight: 10,
      bottom: isMobile(window.navigator).phone ? 0 : 70,
      left: isMobile(window.navigator).phone ? 0 : 100,
      textStyle: {
        color: "#333333",
        fontSize: isMobile(window.navigator).phone ? 8 : 12,
        fontWeight: 600,
      },
      itemGap: isMobile(window.navigator).phone ? 5 : 20,
      seriesIndex: 0, //指定取哪个系列的数据(series.data)，若不设置则会影响图上标点颜色设置。
      pieces: piecesList,
      hoverLink: false, //高亮行为
      selectedMode: false, // 点击不做任何变化
      silent: true, // 设置为true使地图不响应鼠标事件，鼠标悬浮在地图上不显示cursor: pointer
    },
    geo: {
      map: "china",
      zoom: 1.2,
      roam: false, //禁止拖动
      // silent: true, // 设置为true使地图不响应鼠标事件，鼠标悬浮在地图上不显示cursor: pointer
      scaleLimit: {
        max: 1.2,
        min: isMobile(window.navigator).phone ? 1.3 : 1.2,
      },
      label: {
        normal: {
          show: false,
          fontSize: "12",
          color: "#333333",
        },
        emphasis: {
          show: false,
        },
      },
      itemStyle: {
        normal: {
          borderWidth: 1,
          borderColor: "white",
          areaColor: "#EFF4F9",
          shadowColor: "rgba(0, 0, 0, 0)",
        },
        emphasis: {
          areaColor: "#619EDF",
          borderColor: "white",
          shadowColor: "rgba(0, 0, 0, 0.1)",
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 5,
          borderWidth: 2,
        },
      },
      regions: dealRegions(),
    },
    series: [
      {
        name: "",
        type: "map",
        geoIndex: 0,
        label: {
          show: true,
        },
        selectedMode: "false", //不可点击选择变色
        data: dataList,
      },
      {
        type: "scatter",
        coordinateSystem: "geo",
        selectedMode: "false",
        label: {
          normal: {
            show: true,
            formatter: function (params) {
              return params.data.name;
            },
            fontSize: isMobile(window.navigator).phone ? "8" : "12",
            color: "#333333",
            fontWeight: 600,
            fontFamily: "PingFangSC, PingFang SC",
            position: "bottom",
          },
        },
        itemStyle: {
          color: {
            type: "radial",
            x: 0.5,
            y: 0.5,
            r: 0.5,
            colorStops: [
              {
                offset: 0.5,
                color: "#1b56d0",
              },
              {
                offset: 1,
                color: "#1b56d0",
                // color: "rgb(0 0 0 / 0%)", // 100% 处的颜色
              },
            ],
            global: false,
          },
          borderColor: "#1b56d0", //边框白色
          // borderWidth: 1, //边框宽度
        },
        symbolSize: isMobile(window.navigator).phone ? 5 : 10,
        data: [
          { name: "河南", value: [113.665412, 34.757975] },
          { name: "北京", value: [116.405285, 39.904989] },
          { name: "河北", value: [115.202461, 38.445474] },
          { name: "山东", value: [117.000923, 36.675807] },
          { name: "上海", value: [121.472644, 31.231706] },
          { name: "四川", value: [104.065735, 30.659462] },
          { name: "安徽", value: [117.283042, 31.86119] },
          { name: "甘肃", value: [103.823557, 36.058039] },
          { name: "山西", value: [112.549248, 37.857014] },
          { name: "陕西", value: [108.948024, 34.263161] },
          { name: "新疆", value: [85.294711, 41.371801] },
        ],
        zlevel: 1,
      },
    ],
  };

  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "business_container_mobile"
          : "business_container"
      }
    >
      <div className="business_title">业务规模</div>
      <div className="business_title_desc">服务学校4000所+ 服务用户2200万+</div>
      <EChartsReact
        option={option}
        style={
          isMobile(window.navigator).phone
            ? { width: 345, height: 243, marginTop: 30, marginBottom: 40 }
            : { width: 1200, height: 800, marginTop: 70, marginBottom: 95 }
        }
      />
    </div>
  );
}
