import React from "react";
import "./index.scss";
import "./indexMobile.scss";
import _ from "lodash";
import {
  AppData,
  ManagerData,
  RoleData,
} from "../../../../../../utils/solutionData";
import isMobile from "ismobilejs";

export default function SaasService(props) {
  const { managerOnClick, appOnClick } = props;
  const renderLeftView = () => {
    return (
      <div className="item">
        <div className="item_title">1个管理平台</div>
        <div className="left_bg">
          <div className="left_title">学生安全生活服务平台</div>
          <div className="left_content">
            {_.map(ManagerData, (item, i) => {
              return (
                <div
                  className="left_content_item"
                  key={i}
                  onClick={() => {
                    managerOnClick(item.slideId);
                  }}
                >
                  <img className="left_content_item_icon" src={item.icon} />
                  <div className="left_content_item_title">{item.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderMiddleView = () => {
    return (
      <div className="item">
        <div className="item_title">5种身份</div>
        <div className="middle_bg">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 28,
              width: "100%",
            }}
          >
            {_.map([RoleData[0], RoleData[1]], (item, i) => {
              return (
                <div className="middle_item" key={i}>
                  <img className="middle_item_icon" src={item.icon} />
                  <div className="middle_item_title">{item.title}</div>
                </div>
              );
            })}
          </div>
          <div className="middle_student">学生</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {_.map([RoleData[2], RoleData[3]], (item, i) => {
              return (
                <div className="middle_item" key={i}>
                  <img className="middle_item_icon" src={item.icon} />
                  <div className="middle_item_title">{item.title}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderRightView = () => {
    return (
      <div className="item">
        <div className="item_title">N个应用场景</div>
        <div className="right_bg">
          {_.map(AppData, (item, i) => {
            return (
              <div
                className="right_content_item"
                key={i}
                onClick={() => {
                  appOnClick(item.key, item.slideId);
                }}
              >
                <div className="right_content_item_point" />
                <div className="right_content_item_title">{item.title}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "saas_container_mobile"
          : "saas_container"
      }
    >
      <div className="title">AI硬件+企业级SaaS服务，用数字守护孩子成长</div>
      <div
        style={
          isMobile(window.navigator).phone
            ? {
                display: "flex",
                flexDirection: "column",
              }
            : {
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }
        }
      >
        {/* 管理平台 */}
        {renderLeftView()}
        {/* 5种身份 */}
        {renderMiddleView()}
        {/* N个应用场景 */}
        {renderRightView()}
      </div>
    </div>
  );
}
