import React, { useEffect } from "react";
import Layout from "../../components/layout";
import icon from "../../../../assets/images/top_bg1@2x.png";
import icon_2 from "../../../../assets/mobile/top_bg1@3x.png";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import CompanyProfileIndexPage from "./index/index";
import { Routes } from "../../../../utils/routes";
import CompanyProfileHistoryPage from "./history";
import CompanyProfileManagementTeamPage from "./managementTeam";
import CompanyProfileTeamBuildingPage from "./teamBuilding";
import CompanyProfileHonorPage from "./honor";
import isMobile from "ismobilejs";

const CompanyProfilePage = () => {
  return (
    <Layout
      english={"INTRODUCE"}
      title={"公司简介"}
      icon={isMobile(window.navigator).phone ? icon_2 : icon}
      menus={[
        {
          title: "企业简介",
          path: Routes.companyProfileIndex,
        },
        {
          title: "发展历程",
          path: Routes.companyProfileHistory,
        },
        // {
        //   title: '管理团队',
        //   path: Routes.companyProfileManagementTeam
        // },
        {
          title: "荣誉资质",
          path: Routes.companyProfileHonor,
        },
        {
          title: "创新科研",
          path: `${Routes.companyProfileTeamBuilding}/1`,
        },
      ]}
    >
      <Switch>
        <Redirect
          from={Routes.companyProfile}
          to={Routes.companyProfileIndex}
          exact
        />
        <Route
          path={Routes.companyProfileIndex}
          component={CompanyProfileIndexPage}
        />
        <Route
          path={Routes.companyProfileHistory}
          component={CompanyProfileHistoryPage}
        />
        <Route
          path={Routes.companyProfileManagementTeam}
          component={CompanyProfileManagementTeamPage}
        />
        <Route
          path={`${Routes.companyProfileTeamBuilding}/:page`}
          component={CompanyProfileTeamBuildingPage}
        />
        <Route
          path={Routes.companyProfileHonor}
          component={CompanyProfileHonorPage}
        />
      </Switch>
    </Layout>
  );
};
export default withRouter(CompanyProfilePage);
