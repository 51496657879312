import React, { useEffect, useState } from "react";
import banner from "../../../../assets/images/top_bg_dybamic.png";
import banner_2 from "../../../../assets/mobile/top_bg_dybamic@3x.png";
import TopBanner from "../../components/topBanner";
import "./style.scss";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import store, { NEW_DETAIL } from "../../../../utils/store";
import { Routes } from "../../../../utils/routes";
import { useHistory } from "react-router-dom";
import Pagination from "rc-pagination";
import { useMounted } from "../../../../utils/hook";
import isMobile from "ismobilejs";
import _ from "lodash";
const NewsPage = (props) => {
  const isMounted = useMounted();

  const history = useHistory();

  const [list, setList] = useState([]);

  const [pageSize] = useState(8);

  const [total, setTotal] = useState(0);
  const [listMobile, setListMobile] = useState([]);
  const [pageMobile, setPageMobile] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const res = await Axios({
        url: Apis.news,
        params: {
          current: props.match.params.page,
          pageSize: pageSize,
          status: "1",
        },
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      setList(data.list);
      setTotal(data.pagination.total);
    };

    (async () => {
      if (!isMobile(window.navigator).phone) {
        await getData();
      }
    })();
  }, [props.match.params.page]);

  useEffect(() => {
    const getDataMobile = async (page) => {
      const res = await Axios({
        url: Apis.news,
        params: {
          current: page,
          pageSize: pageSize,
          status: "1",
        },
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      if (_.isEmpty(data.list)) {
        setHasMore(false);
        return false;
      } else {
        setListMobile([...listMobile, ...data.list]);
      }
    };

    (async () => {
      if (isMobile(window.navigator).phone) {
        await getDataMobile(pageMobile);
      }
    })();
  }, [pageMobile]);

  const detail = (item) => {
    if (!item) return false;
    store.setObject(NEW_DETAIL, item);
    history.push(`${Routes.newsDetail}/${item.id}`);
  };

  const goPage = (page) => {
    history.replace(`${Routes.news}/${page}`);
  };

  return (
    <div className={"NewsPage"}>
      <TopBanner
        icon={isMobile(window.navigator).phone ? banner_2 : banner}
        title={"公司动态"}
        english={"DYNAMIC"}
      />
      <div className={"content"}>
        <div className={"list"}>
          {_.map(
            isMobile(window.navigator).phone ? listMobile : list,
            (item, index) => (
              <div
                className={"item"}
                key={`NewsPage_item_${index}`}
                onClick={() => detail(item)}
              >
                <div className={"icon"}>
                  <img
                    src={`${process.env["ASSETS_URL"]}${item.image}`}
                    alt=""
                  />
                </div>
                <div className={"infoBox"}>
                  <div className={"title"}>{item.title}</div>
                  <div className={"des"}>{item.desc}</div>
                  {/* <div className={"bottomIcon"} /> */}
                  <div className="more_bg">
                    <div className="more_title">查看更多</div>
                    <div className="more_img" />
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {hasMore && (
          <div className="more_button_page_bg">
            <div
              className="more_button_bg"
              onClick={() => {
                setPageMobile(pageMobile + 1);
              }}
            >
              查看更多
            </div>
          </div>
        )}

        <Pagination
          onChange={goPage}
          className={"Pagination"}
          current={Number(props.match.params.page) || 1}
          total={total}
          pageSize={pageSize}
          defaultCurrent={1}
        />
      </div>
    </div>
  );
};
export default NewsPage;
