import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Routes } from "../../../../utils/routes";
import { useHistory } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import ScrollAnimation from "react-animate-on-scroll";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import store, {
  HOME_NEWS_LEFT,
  HOME_NEWS_RIGHT,
  NEW_DETAIL,
} from "../../../../utils/store";
import { useMounted } from "../../../../utils/hook";
import moment from "moment";

SwiperCore.use([Autoplay]);

const NewComponent = () => {
  const isMounted = useMounted();

  const history = useHistory();
  //左侧图文新闻
  const [pictureNews, setPictureNews] = useState([]);
  //右侧文字新闻
  const [textNews, setTextNews] = useState([]);

  const [swiperActive, setSwiperActive] = useState(0);

  useEffect(() => {
    setPictureNews(store.getArray(HOME_NEWS_LEFT));
    setTextNews(store.getArray(HOME_NEWS_RIGHT));
    const getData = async () => {
      const res = await Axios({
        url: Apis.homeNews,
        params: {
          size: 10,
        },
      });
      const { code = 200, data = {} } = res;
      if (code !== 200 || !isMounted) return false;
      let { recommend_news: gallery_news = [], news = [] } = data;
      gallery_news = gallery_news.map((item) => ({
        ...item,
        image: `${process.env["ASSETS_URL"]}${item.image}`,
      }));
      news = news.map((item) => ({
        ...item,
        image: `${process.env["ASSETS_URL"]}${item.image}`,
      }));
      store.setArray(HOME_NEWS_LEFT, gallery_news);
      store.setArray(HOME_NEWS_RIGHT, news);
      setPictureNews(gallery_news);
      const newNews = news.slice(0, 8);
      setTextNews(newNews);
    };

    (async () => {
      await getData();
    })();
  }, []);

  const detail = (item) => {
    if (!item) return false;
    store.setObject(NEW_DETAIL, item);
    history.push(`${Routes.newsDetail}/${item.id}`);
  };

  return (
    <div className={"newsComponent"}>
      <div className={"content"}>
        <ScrollAnimation
          className={"title"}
          animateIn={"animate__fadeInUp"}
          animateOnce={true}
        >
          <div className={"text"}>最新动态</div>
          <div className={"info"}>Company Dynamic</div>
        </ScrollAnimation>
        <div className={"flexContent"}>
          <ScrollAnimation
            className={"swiperLeft"}
            animateIn={"animate__fadeInLeft"}
            animateOnce={true}
          >
            <div className={"swiperBox"}>
              <Swiper
                autoplay={{ delay: 3000 }}
                observeParents={true}
                loop={true}
                updateOnWindowResize={true}
                resizeObserver={true}
                navigation
                className={"swiperBoxContent"}
                onSlideChange={(item) => setSwiperActive(item.realIndex)}
              >
                {pictureNews.map(
                  (
                    item = {
                      image: "",
                      id: "",
                      created_at: "",
                      title: "",
                    },
                    index
                  ) => (
                    <SwiperSlide key={`pictureNews_${index}`}>
                      <img
                        className={"leftNewsItem"}
                        src={`${item.image}`}
                        onClick={() => detail(item)}
                        alt=""
                      />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
            <div className={"time"}>
              {pictureNews[swiperActive]
                ? moment(pictureNews[swiperActive].publish_at).format(
                    "YYYY-MM-DD"
                  )
                : ""}
            </div>
            <div
              className={"title"}
              onClick={() => detail(pictureNews[swiperActive])}
            >
              {pictureNews[swiperActive] ? pictureNews[swiperActive].title : ""}
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className={"list"}
            animateIn={"animate__fadeInRight"}
            animateOnce={true}
          >
            {textNews.map((item = { title: "", publish_at: "" }, index) => (
              <div
                onClick={() => detail(item)}
                className="item"
                key={`textNews_item_${index}`}
              >
                <div className={"title"}>{item.title}</div>
                <div className={"time"}>
                  {moment(item.publish_at).format("YYYY-MM-DD")}
                </div>
              </div>
            ))}
          </ScrollAnimation>
        </div>
        <Link to={`${Routes.news}/1`} className={"moreButton"}>
          KNOW MORE
        </Link>
      </div>
    </div>
  );
};
export default NewComponent;
