import React, { useEffect, useState } from "react";
import "./style.scss";
import "./styleMobile.scss";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import logo_1 from "../../../../assets/images/school_logo01@2x.png";
import logo_2 from "../../../../assets/images/school_logo02@2x.png";
import logo_3 from "../../../../assets/images/school_logo03@2x.png";
import logo_4 from "../../../../assets/images/school_logo04@2x.png";
import logo_5 from "../../../../assets/images/school_logo05@2x.png";
import logo_6 from "../../../../assets/images/school_logo06@2x.png";
import logo_7 from "../../../../assets/images/school_logo07@2x.png";
import logo_8 from "../../../../assets/images/school_logo08@2x.png";
import logo_9 from "../../../../assets/images/school_logo09@2x.png";
import logo_10 from "../../../../assets/images/school_logo10@2x.png";
import logo_11 from "../../../../assets/images/school_logo11@2x.png";
import logo_12 from "../../../../assets/images/school_logo12@2x.png";
import logo_13 from "../../../../assets/images/school_logo13@2x.png";
import logo_14 from "../../../../assets/images/school_logo14@2x.png";
import logo_15 from "../../../../assets/images/school_logo15@2x.png";
import logo_16 from "../../../../assets/images/school_logo16@2x.png";
import WalkingLantern from "../walkingLantern";
import _ from "lodash";
import { useMounted } from "../../../../utils/hook";
import isMobile from "ismobilejs";

const Exlist = [
  {
    id: "107855444877279357",
    title: "中国银行",
    url: "/joinuscn-admin/images/2023-08-11/01-27-24-2f51dd66e3b0fce7154a64e0e1b4aeff.png",
    order_num: 27,
    created_at: "2023-08-11T09:27:34.792248+08:00",
    updated_at: "2023-08-11T09:27:34.792248+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107856273738858621",
    title: "中国建设银行",
    url: "/joinuscn-admin/images/2023-08-11/01-35-31-6795f9ad237cbd20cbd2f13b4d7df340.png",
    order_num: 28,
    created_at: "2023-08-11T09:35:48.83124+08:00",
    updated_at: "2023-08-11T09:35:48.83124+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107856300330745981",
    title: "中国农业银行",
    url: "/joinuscn-admin/images/2023-08-11/01-35-55-3f1aa6217123d577aad716c252569139.png",
    order_num: 29,
    created_at: "2023-08-11T09:36:04.685342+08:00",
    updated_at: "2023-08-11T09:36:04.685342+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857290236821629",
    title: "中国移动",
    url: "/joinuscn-admin/images/2023-08-11/01-45-45-59c8ca41e85af0b0f006008232511001.png",
    order_num: 30,
    created_at: "2023-08-11T09:45:54.713792+08:00",
    updated_at: "2023-08-11T09:45:54.713792+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857372260630653",
    title: "大数据",
    url: "/joinuscn-admin/images/2023-08-11/01-46-38-9ecd477564b836c1ff190e5b83dade25.png",
    order_num: 31,
    created_at: "2023-08-11T09:46:43.610172+08:00",
    updated_at: "2023-08-11T09:46:43.610172+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857396201717885",
    title: "中关村二维码",
    url: "/joinuscn-admin/images/2023-08-11/01-46-48-3316b5b24541faa02aef736a84533022.png",
    order_num: 32,
    created_at: "2023-08-11T09:46:57.877259+08:00",
    updated_at: "2023-08-11T09:46:57.877259+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857420193136765",
    title: "中国信安",
    url: "/joinuscn-admin/images/2023-08-11/01-47-02-fb6515aa4cd43a22fe359c1139e5225b.png",
    order_num: 33,
    created_at: "2023-08-11T09:47:12.176744+08:00",
    updated_at: "2023-08-11T09:47:12.176744+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857453948895357",
    title: "北工大",
    url: "/joinuscn-admin/images/2023-08-11/01-47-16-c386d9e786e7877d38f6fb7824d6ef3e.png",
    order_num: 34,
    created_at: "2023-08-11T09:47:32.300649+08:00",
    updated_at: "2023-08-11T09:47:32.300649+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857476715577469",
    title: "首师大",
    url: "/joinuscn-admin/images/2023-08-11/01-47-37-6efcbe6d0d3820352ec6b06c540fefaa.png",
    order_num: 35,
    created_at: "2023-08-11T09:47:45.86526+08:00",
    updated_at: "2023-08-11T09:47:45.86526+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857500723773565",
    title: "河南新闻",
    url: "/joinuscn-admin/images/2023-08-11/01-47-49-52f9a59f389b59ff2f3e68d31f19282d.png",
    order_num: 36,
    created_at: "2023-08-11T09:48:00.173054+08:00",
    updated_at: "2023-08-11T09:48:00.173054+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857522785812605",
    title: "阿里云",
    url: "/joinuscn-admin/images/2023-08-11/01-48-08-ae093dd5f58937bf96ca05b8f908049f.png",
    order_num: 37,
    created_at: "2023-08-11T09:48:13.32475+08:00",
    updated_at: "2023-08-11T09:48:13.32475+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857537046446205",
    title: "华为云",
    url: "/joinuscn-admin/images/2023-08-11/01-48-16-cf5724ef2e8a206574f830713146c416.png",
    order_num: 38,
    created_at: "2023-08-11T09:48:21.8288+08:00",
    updated_at: "2023-08-11T09:48:21.8288+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857555031621757",
    title: "微信支付",
    url: "/joinuscn-admin/images/2023-08-11/01-48-27-93d30e0db40b99a9066ed6f6cb44ba83.png",
    order_num: 39,
    created_at: "2023-08-11T09:48:32.5422+08:00",
    updated_at: "2023-08-11T09:48:32.5422+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857571188080765",
    title: "支付宝",
    url: "/joinuscn-admin/images/2023-08-11/01-48-37-1d89d4d68ed2579d0b95f54ac558b1c8.png",
    order_num: 40,
    created_at: "2023-08-11T09:48:42.173079+08:00",
    updated_at: "2023-08-11T09:48:42.173079+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857588267286653",
    title: "广发银行",
    url: "/joinuscn-admin/images/2023-08-11/01-52-46-6e002aa8763098fe48a133ecf9582809.png",
    order_num: 41,
    created_at: "2023-08-11T09:48:52.353658+08:00",
    updated_at: "2023-08-11T09:52:47.261623+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857605916917885",
    title: "中信银行",
    url: "/joinuscn-admin/images/2023-08-11/01-48-56-ebf85cf358e6da9ee0bf8d80ac204a88.png",
    order_num: 42,
    created_at: "2023-08-11T09:49:02.871484+08:00",
    updated_at: "2023-08-11T09:49:02.871484+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857632492028029",
    title: "平安银行",
    url: "/joinuscn-admin/images/2023-08-11/01-49-07-4b4a9442f87e265dca056c4d0c205cee.png",
    order_num: 43,
    created_at: "2023-08-11T09:49:18.716636+08:00",
    updated_at: "2023-08-11T09:49:18.716636+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857660744859773",
    title: "AI-LINK",
    url: "/joinuscn-admin/images/2023-08-11/01-49-22-e34bc65d6bbc2fe3e44e2217f762539f.png",
    order_num: 44,
    created_at: "2023-08-11T09:49:35.553747+08:00",
    updated_at: "2023-08-11T09:49:35.553747+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857683192774781",
    title: "智慧城市",
    url: "/joinuscn-admin/images/2023-08-11/01-49-41-646bd5001b7874f1347d8319139d9e5b.png",
    order_num: 45,
    created_at: "2023-08-11T09:49:48.931831+08:00",
    updated_at: "2023-08-11T09:49:48.931831+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857708476039293",
    title: "市政交通一卡通",
    url: "/joinuscn-admin/images/2023-08-11/01-50-01-7f0c12863d26e38f8dea3e3cce1694f6.png",
    order_num: 46,
    created_at: "2023-08-11T09:50:04.007285+08:00",
    updated_at: "2023-08-11T09:50:04.007285+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857727803392125",
    title: "绿城通",
    url: "/joinuscn-admin/images/2023-08-11/01-50-10-110c1df941d7edf5c45bafc3b060b3a8.png",
    order_num: 47,
    created_at: "2023-08-11T09:50:15.529067+08:00",
    updated_at: "2023-08-11T09:50:15.529067+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
  {
    id: "107857741795590269",
    title: "天迈科技",
    url: "/joinuscn-admin/images/2023-08-11/01-50-18-cb62e62f1c28abf7cd673689620b4df3.png",
    order_num: 48,
    created_at: "2023-08-11T09:50:23.867269+08:00",
    updated_at: "2023-08-11T09:50:23.867269+08:00",
    news_picture_type_id: "77151718969737234",
    news_picture_type: {
      id: "77151718969737234",
      name: "合作伙伴",
      created_at: "2023-01-11T13:53:28.04051+08:00",
      updated_at: "2023-01-11T13:53:28.04051+08:00",
      list: null,
    },
  },
];
const PartnerCase = () => {
  const isMounted = useMounted();

  const [selectedKey, setSelectedKey] = useState("partner");
  const [partnerList, setPartnerList] = useState([]);
  const list = [
    {
      title: "合作伙伴",
      key: "partner",
    },
    // {
    //   title: "服务学校",
    //   key: "school",
    // },
  ];

  useEffect(() => {
    (async () => {
      await getData();
    })();
  }, []);
  const getData = async () => {
    const res = await Axios({
      url: Apis.partners,
    });
    const { code, data } = res;
    if (code !== 200 || !isMounted) return false;
    setPartnerList(
      _.map(
        _.isEmpty(data) ? Exlist : data,
        (item) => process.env["ASSETS_URL"] + item.url
      )
    );
  };

  const renderPartner = () => {
    const shuffleArray = _.shuffle(partnerList);
    const middleIndex = Math.floor(partnerList.length / 2);
    const firstHalf = _.slice(partnerList, 0, middleIndex);
    const secondHalf = _.slice(partnerList, middleIndex);

    const divided = _.chunk(partnerList, Math.ceil(partnerList.length / 3));

    if (isMobile(window.navigator).phone && divided.length === 3) {
      let o = 60;
      if (window.orientation === 90 || window.orientation === -90) {
        console.log("设备处于横屏状态");
        o = 70;
      }
      return (
        <div className={"partnerCaseContent_mobile"}>
          <div className={"ani_mobile"}>
            <WalkingLantern
              data={divided[0].concat(divided[0])}
              itemStyle={{
                width: 166,
                height: 50,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 12,
              }}
              animation={"loop infinite linear 58s"}
            />
          </div>
          <div className={"ani_mobile"} style={{ height: o }}>
            <WalkingLantern
              data={divided[1].concat(divided[1])}
              itemStyle={{
                width: 166,
                height: 50,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 12,
              }}
              animation={"loop infinite linear 60s"}
            />
          </div>
          <div className={"ani_mobile"} style={{ height: o }}>
            <WalkingLantern
              data={divided[2].concat(divided[2])}
              itemStyle={{
                width: 166,
                height: 50,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 12,
              }}
              animation={"loop infinite linear 62s"}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={"partnerCaseContent"}>
        <div className={"ani"}>
          <WalkingLantern
            data={firstHalf.concat(firstHalf)}
            itemStyle={{
              width: 342,
              height: 102,
            }}
            animation={"loop infinite linear 60s"}
          />
        </div>
        <div className={"ani"}>
          <WalkingLantern
            data={secondHalf.concat(secondHalf).reverse()}
            itemStyle={{
              width: 342,
              height: 102,
            }}
            animation={"loop infinite linear 60s"}
          />
        </div>
        {/* <div className={"ani"}>
          <WalkingLantern
            data={shuffleArray.concat(shuffleArray)}
            itemStyle={{
              width: 342,
              height: 102,
            }}
            animation={"loop infinite linear 30s"}
          />
        </div> */}
      </div>
    );
  };

  // const renderSchool = () => {
  //   return (
  //     <div className={"partnerCaseContent"}>
  //       <div className={"ani"}>
  //         <WalkingLantern
  //           data={[
  //             logo_1,
  //             logo_2,
  //             logo_3,
  //             logo_4,
  //             logo_5,
  //             logo_6,
  //             logo_13,
  //             logo_14,
  //             logo_1,
  //             logo_2,
  //             logo_3,
  //             logo_4,
  //             logo_5,
  //             logo_6,
  //             logo_13,
  //             logo_14,
  //             logo_1,
  //             logo_2,
  //             logo_3,
  //             logo_4,
  //             logo_5,
  //             logo_6,
  //           ]}
  //         />
  //       </div>
  //       <div className={"ani"}>
  //         <div className={"ani"}>
  //           <WalkingLantern
  //             data={[
  //               logo_7,
  //               logo_8,
  //               logo_9,
  //               logo_10,
  //               logo_11,
  //               logo_12,
  //               logo_15,
  //               logo_16,
  //               logo_7,
  //               logo_8,
  //               logo_9,
  //               logo_10,
  //               logo_11,
  //               logo_12,
  //               logo_15,
  //               logo_16,
  //               logo_7,
  //               logo_8,
  //               logo_9,
  //               logo_10,
  //               logo_11,
  //               logo_12,
  //             ]}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderContent = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div className={"partnerCase_mobile"}>
          <div className="partnerCase_items_mobile">
            {list.map((item = { path: "", title: "", active: "" }, index) => (
              <div
                className={`partnerCase_item_mobile`}
                key={item.key}
                onClick={() => {
                  setSelectedKey(item.key);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
          {selectedKey === "partner" && renderPartner()}
        </div>
      );
    }
    return (
      <div className={"partnerCase"}>
        <div className="partnerCase_items">
          {list.map((item = { path: "", title: "", active: "" }, index) => (
            <div
              className={`partnerCase_item ${
                selectedKey == item.key ? "active" : ""
              }`}
              key={item.key}
              onClick={() => {
                setSelectedKey(item.key);
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
        {selectedKey === "partner" && renderPartner()}
        {/* {selectedKey === "school" && renderSchool()} */}
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {renderContent()}
    </div>
  );
};
export default PartnerCase;
