import React, { useEffect, useState } from "react";
import "./style.scss";
import Axios from "../../../../../utils/axios";
import Apis from "../../../../../utils/apis";
import { useMounted } from "../../../../../utils/hook";

const CompanyProfileHonorPage = () => {
  const isMounted = useMounted();
  const [list, setList] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const res = await Axios({
        url: Apis.honorary,
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      setList(data);
    };

    (async () => {
      await getData();
    })();
  }, []);

  return (
    <div className={"CompanyProfileHonorPage"}>
      {/* <div className={"title"}>是荣誉 更是责任和动力</div>
      <div className={"info"}>
        家音顺达坚持技术创新，主营软件和产品均拥有独立知识产权，并通过了国家级软件质量测试。
      </div>
      <p className={"small"}>
        （注：下面展示的荣誉和资质包含公司、分公司及控股子公司的相关内容。）
      </p> */}
      <div className={"list"}>
        {list.map((item, index) => (
          <div className={"item"} key={`companyProfileHonorPage_item_${index}`}>
            <div className={"img"}>
              <img src={`${process.env["ASSETS_URL"]}${item.url}`} alt="" />
            </div>
            <div className={"name"}>
              <div className={"nameContent"}>{item.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default CompanyProfileHonorPage;
