import React, { useEffect, useRef, useState } from "react";
import "./indes.scss";
import "./indexMobile.scss";
import SaasService from "./components/saasService";
import HundredAdd from "./components/100+";
import TechnologySystem from "./components/ technologySystem";
import SafetySupervision from "./components/safetySupervision";
import LifeServices from "./components/lifeServices";
import BusinessScale from "./components/businessScale";
import CustomerCase from "../../components/customerCase";
import RiskSystem from "./components/riskSystem";
import isMobile from "ismobilejs";

export default function SolutionPageV2() {
  const safeRef = useRef();
  const lifeRef = useRef();
  /**前往锚点 */
  const goAnchor = (selector) => {
    document.querySelector(selector)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const renderTopView = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div className="so_top_bg_mobile">
          <div className="title_mobile">青于蓝校园</div>
          <div className="main_title_mobile">K12校园全场景数字化解决方案</div>
          <div className="sub_title_mobile">
            以学生安全监管和生活服务为核心，构建"局、校、班、家"四位一体、上下联动的数字化管理平台。
          </div>
        </div>
      );
    }
    return (
      <div className="so_top_bg">
        <div className="title">青于蓝校园</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="main_title">K12校园全场景数字化解决方案</div>
          <div className="sub_title">
            以学生安全监管和生活服务为核心，构建"局、校、班、家"四位一体、上下联动的数字化管理平台。
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "so_container_mobile"
          : "so_container"
      }
    >
      {/* 顶部样式 */}
      {renderTopView()}
      {/* saas */}
      <SaasService
        managerOnClick={(slideId) => {
          goAnchor(slideId);
        }}
        appOnClick={(key, slideId) => {
          if (slideId == "#safeId") {
            goAnchor(slideId);
            safeRef.current && safeRef.current.updateCurrent(key);
          }
          if (slideId == "#lifeId") {
            goAnchor(slideId);
            lifeRef.current && lifeRef.current.updateCurrent(key);
          }
          if (slideId == "#riskId") {
            goAnchor(slideId);
          }
        }}
      />
      {/* 100+ */}
      <HundredAdd />
      {/* 核心技术体系 */}
      <TechnologySystem />
      {/* 安全监管 */}
      <SafetySupervision slideId={"safeId"} ref={safeRef} />
      {/* 生活服务 */}
      <LifeServices slideId={"lifeId"} ref={lifeRef} />
      {/* 风险系统 */}
      <RiskSystem slideId={"riskId"} />
      {/* 业务规模 */}
      <BusinessScale />
      {/* 客户案例*/}
      <CustomerCase />
    </div>
  );
}
