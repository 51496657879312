import {isArray, isObject} from "is-what/dist";

export default class store {
  static nameSpace = "JOIN_US";

  static set(key = '', value = '') {
    try {
      if (!key || value === '') return false;
      localStorage.setItem(`${this.nameSpace}_${key}`, value);
      return true;
    } catch (err) {
      return false;
    }
  }

  static get(key = '') {
    try {
      if (!key) return false;
      return localStorage.getItem(`${this.nameSpace}_${key}`);
    } catch (err) {
      return false;
    }
  }

  static remove(key = '') {
    try {
      if (!key) return false;
      localStorage.removeItem(`${this.nameSpace}_${key}`);
      return true;
    } catch (err) {
      return false;
    }
  }

  static clean() {
    try {
      localStorage.clear();
      return true;
    } catch (err) {
      return false;
    }
  }

  static setArray(key = '', value = []) {
    if (!isArray(value)) {
      return false;
    }
    let obj = {};
    obj[key] = value;
    return this.set(key, JSON.stringify(obj));
  }

  static getArray(key = '') {
    const res = this.get(key);
    if (res === false) return [];
    let value;
    try {
      value = JSON.parse(res);
    } catch (err) {
      console.log(value);
    }
    if (!isObject(value)) return [];
    return value[key] || [];
  }


  static setObject(key = '', value = {}) {
    if (!isObject(value)) {
      return false;
    }
    return this.set(key, JSON.stringify(value, {}));
  }

  static getObject(key = '') {
    const res = this.get(key);
    if (res === false) return {};
    let value;
    try {
      value = JSON.parse(res);
    } catch (err) {
      console.log(err)
    }
    if (!isObject(value)) return {};
    return value;
  }
}

const NEW_DETAIL = "NEW_DETAIL";
const SOCIAL_WELFARE = "SOCIAL_WELFARE";
const HOME_NEWS_LEFT = "HOME_NEWS_LEFT";
const HOME_NEWS_RIGHT = "HOME_NEWS_RIGHT";
const COOPERATION = "COOPERATION";
const JOBS = "JOBS";
const JOBS_MENUS = "JOBS_MENUS";
export {
  NEW_DETAIL,
  SOCIAL_WELFARE,
  HOME_NEWS_LEFT,
  HOME_NEWS_RIGHT,
  COOPERATION,
  JOBS,
  JOBS_MENUS
}
