import React, {useEffect, useState} from "react";
import './style.scss';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useLocation} from 'react-router-dom';

const RecruitmentInformation = (props) => {

  const [isShow, setIsShow] = useState(false);
  const {data = {duty: '', welfare: '', requirement: ''}} = props;
  const location = useLocation();

  useEffect(() => {
    setIsShow(false);
  }, [location.pathname])

  return <div className={'RecruitmentInformation'}>
    <div className={'title'}>{data.title}</div>
    <div className={'info'}>{data.site} | {data.typeName}</div>

    <div className={`hideContent ${isShow ? 'hide' : 'show'} `}>
      <pre dangerouslySetInnerHTML={{__html: data.desc}}/>
    </div>
    <div className={`showContent ${isShow ? 'show' : 'hide'} `}>
      <div className={'inlineTitle'}>岗位职责</div>
      <div className={'inlineText'}>
        <pre dangerouslySetInnerHTML={{__html: data.duty}}/>
      </div>
      <div className={'inlineTitle'}>任职要求</div>
      <div className={'inlineText'}>
        <pre dangerouslySetInnerHTML={{__html: data.requirement}}/>
      </div>
      <div className={'inlineTitle'}>福利待遇</div>
      <div className={'inlineText'}>
        <pre dangerouslySetInnerHTML={{__html: data.welfare}}/>
      </div>
      <div className={'copy'}>
        <div className={'inlineBodyTitle'}>简历发送至 {data.email}</div>
        <CopyToClipboard className={'copyButton'} text={'hr@967111.com'} onCopy={(t, r) => {
          if (r) {
            alert('复制成功')
          } else {
            alert('复制失败，请手动复制。')
          }
        }}>
          <div>复制</div>
        </CopyToClipboard>
      </div>
    </div>
    <div className={'moreButtonBox'}>
      <div className={'moreButton'} onClick={() => setIsShow(!isShow)}>
        <div className={'text'}>{isShow ? '收起' : '展开更多'}</div>
        <div className={`icon ${isShow && 'active'}`}/>
      </div>
    </div>
  </div>
}
export default RecruitmentInformation;
