import React from "react";
import './style.scss';
import ZyManager from '../../../../../assets/images/boos/photo1@2x.png';
import GkManager from '../../../../../assets/images/boos/photo2@2x.png';
import LzlManager from '../../../../../assets/images/boos/photo3@2x.png';
import YwgManager from '../../../../../assets/images/boos/photo4@2x.png';
import SyManager from '../../../../../assets/images/boos/photo8@2x.png';
import WfManager from '../../../../../assets/images/boos/photo9@2x.png';

const CompanyProfileManagementTeamPage = () => {
  return <div className={'CompanyProfileManagementTeamPage'}>
    <div className={'list'}>
      <div className={'item boos'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={ZyManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>郑义</div>
            <div className={'level'}>董事长、总经理</div>
          </div>
        </div>
        <div className={'right'}>
          毕业西安交通大学，于2010年成立家音顺达，并担任公司总经理，始终以一种强烈的社会责任感和使命感，大胆开拓、不断创新，一手抓公司组织管理，一手抓产品技术研发与升级，不断为校园提供安全和便利，为社会创造价值，使公司始终处于行业领先地位。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={GkManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>耿科</div>
            <div className={'level'}>董事、副总经理 <br/> 技术研发中心</div>
          </div>
        </div>
        <div className={'right'}>
          毕业于北京工商大学，18年软件开发、10年项目经理经验，CCNA思科认证网络工程师；CCNA思科认证网络专业人员；MCSE微软认证系统工程师。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={LzlManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>李忠良</div>
            <div className={'level'}>董事、市场总监 <br/> 市场营销中心</div>
          </div>
        </div>
        <div className={'right'}>
          毕业于西安电子科技大学，28年销售工作经验（其中10年互联网教育行业销售和管理经验），拥有丰富的销售经验和沟通技巧，多次带领团队超额完成销售业绩，并为公司培养了多名优秀的区域经理及销售骨干。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={YwgManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>杨卫国</div>
            <div className={'level'}>资深顾问<br/>稽核中心</div>
          </div>
        </div>
        <div className={'right'}>
          杨总 清华大学无线电半导体专业，毕业后长期在电子工业部第4057厂从事短波单边带电台设计工作，后加入家音顺达从事市场开发，从0到1开创河南区域业务 平顶山 南阳 驻马店。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={YwgManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>张宝才</div>
            <div className={'level'}>资深顾问 <br/>供应管理中心</div>
          </div>
        </div>
        <div className={'right'}>
          毕业于西安电子科技大学，28年销售工作经验（其中10年互联网教育行业销售和管理经验），并为公司培养了多名优秀的区域经理及销售骨干。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={YwgManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>李志鹏</div>
            <div className={'level'}>副总经理<br/>财务中心
            </div>
          </div>
        </div>
        <div className={'right'}>
          毕业于西安电子科技大学，28年销售工作经验（其中10年互联网教育行业销售和管理经验），并为公司培养了多名优秀的区域经理及销售骨干。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={YwgManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>李华</div>
            <div className={'level'}>副总经理<br/>行政人事管理中心
            </div>
          </div>
        </div>
        <div className={'right'}>
          毕业于西安电子科技大学，28年销售工作经验（其中10年互联网教育行业销售和管理经验），并为公司培养了多名优秀的区域经理及销售骨干。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={SyManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>宋悦</div>
            <div className={'level'}>副总经理 <br/> 财互联网运营中心
            </div>
          </div>
        </div>
        <div className={'right'}>
          毕业于西安电子科技大学，28年销售工作经验（其中10年互联网教育行业销售和管理经验）多次带领团队超额完成销售业绩，并为公司培养了多名优秀的区域经理及销售骨干。
        </div>
      </div>

      <div className={'item'}>
        <div className={'left'}>
          <div className={'head'}>
            <img src={WfManager} alt=""/>
          </div>
          <div className={'info'}>
            <div className={'name'}>王菲</div>
            <div className={'level'}>副总经理 <br/> 乘车安项目中心
            </div>
          </div>
        </div>
        <div className={'right'}>
          毕业于西安电子科技大学，28年销售工作经验（其中10年互联网教育行业销售和管理经验）并为公司培养了多名优秀的区域经理及销售骨干。
        </div>
      </div>


    </div>
  </div>;
}
export default CompanyProfileManagementTeamPage;
