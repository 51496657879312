import React from "react";
import './style.scss';
import banner from "../../../../../assets/images/top_bg6@2x.jpg";
import banner_2 from "../../../../../assets/mobile/top_bg6@3x.png";
import TopBanner from "../../../components/topBanner";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/swiper.scss';
import w_1 from '../../../../../assets/work/work1.jpg';
import w_2 from '../../../../../assets/work/work2.jpg';
import w_3 from '../../../../../assets/work/work3.jpg';
import w_4 from '../../../../../assets/work/work4.jpg';
import w_5 from '../../../../../assets/work/work5.jpg';
import w_6 from '../../../../../assets/work/work6.jpg';
import {Link} from "react-router-dom";
import {Routes} from "../../../../../utils/routes";
import isMobile from "ismobilejs";

SwiperCore.use([Autoplay, Navigation, Pagination]);

const JoinUsHomePage = () => {
  return <div className={'JoinUsHomePage'}>
    <TopBanner icon={isMobile(window.navigator).phone ? banner_2 : banner} title={'加入我们'} english={'JOINUS'}/>
    {/*加入我们入口*/}
    <div className={'content buttonBox'}>
      <Link to={`${Routes.joinUsIndex}/0?type=1`} className={'item'}>
        <div className={'bg'}/>
        <div className={'buttonBoxContent'}>
          <div className={'title'}>社会招聘</div>
          <div className={'info'}>
            <div className={'text'}>KNOW MORE</div>
            <div className={'icon'}/>
          </div>
        </div>
      </Link>
      <Link to={`${Routes.joinUsIndex}/0?type=2`} className={'item'}>
        <div className="bg"/>
        <div className="buttonBoxContent">
          <div className={'title'}>校园招聘</div>
          <div className={'info'}>
            <div className={'text'}>KNOW MORE</div>
            <div className={'icon'}/>
          </div>
        </div>
      </Link>
    </div>
    {/*工作环境*/}
    <div className={'content workEnvironment'}>
      <div className={'title'}>工作环境</div>
      <div className={'workEnvironmentContent'}>
        <div className={'workEnvironmentPrev workEnvironmentNavigation'}/>
        <Swiper
          className={'workEnvironmentContentSwiper'}
          autoplay={{delay: 3000}}
          updateOnWindowResize={true}
          resizeObserver={true}
          loop={true}
          navigation={{nextEl: '.workEnvironmentNext', prevEl: '.workEnvironmentPrev'}}
          pagination={{clickable: true, el: '.workEnvironmentContentPagination'}}
        >
          <SwiperSlide><img src={w_1} alt=""/></SwiperSlide>
          <SwiperSlide><img src={w_2} alt=""/></SwiperSlide>
          <SwiperSlide><img src={w_3} alt=""/></SwiperSlide>
          <SwiperSlide><img src={w_4} alt=""/></SwiperSlide>
          <SwiperSlide><img src={w_5} alt=""/></SwiperSlide>
          <SwiperSlide><img src={w_6} alt=""/></SwiperSlide>
        </Swiper>
        <div className={'workEnvironmentNext workEnvironmentNavigation'}/>
      </div>
      <div className={'workEnvironmentContentPagination'}/>
    </div>
    {/*员工福利*/}
    <div className={'employeeWelfare'}>
      <div className={'title'}>员工福利</div>
      <div className={'content'}>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>充分的基本保障</div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>员工关怀</div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>带薪休假</div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>各类补助</div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>健康关怀</div>
        </div>
      </div>
    </div>
    {/*员工发展*/}
    <div className={'staffDevelopment'}>
      <div className={'title'}>
        <div className={'hover'}/>
        <div className={'staffDevelopmentContent'}>员工发展</div>
      </div>
      <div className={'content'}>
        <div className={'item'}>
          <div className={'hover'}/>
          <div className={'staffDevelopmentContent'}>完善的培训体系</div>
        </div>
        <div className={'item'}>
          <div className={'hover'}/>
          <div className={'staffDevelopmentContent'}>完善的晋升通道</div>
        </div>
        <div className={'item'}>
          <div className={'hover'}/>
          <div className={'staffDevelopmentContent'}>融洽的工作氛围</div>
        </div>
        <div className={'item'}>
          <div className={'hover'}/>
          <div className={'staffDevelopmentContent'}>广阔的上升空间</div>
        </div>
      </div>
    </div>
  </div>
}
export default JoinUsHomePage;
