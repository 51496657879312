import React, {useState} from "react";
import './style.scss';
import isMobile from "ismobilejs";
import img_1 from "../../../../../../assets/images/data_img1@2x.png";
import img_2 from "../../../../../../assets/images/data_img2@2x.png";
import more_blue from '../../../../../../assets/images/more_blue@2x.png';
import {useHistory} from "react-router-dom";
import {Routes} from "../../../../../../utils/routes";

const BigDataPage = () => {
  const [active, setActive] = useState(0);
  const history = useHistory();
  return <div className={'BigDataPage'}>
    <div className={'headTitle'}>公共大数据解决方案</div>
    <div className={'headInfo'}>面向家庭对大数据日益增长的需求，推进大数据在公共交通、就业、法律、住房等领域的应用，让数据更好地服务社会、造福家庭。</div>
    {
      isMobile(window.navigator).phone ? <div className={'DigitalFinancePageContentMobile'}>

        <div className={'item'} onClick={() => history.push(Routes.solutionBigDataRideSafety)}>
          <img src={img_1} alt=""/>
          <div className={'title'}>乘车安</div>
          <div className={'info'}>乘车安将家庭数字化服务延伸到公共出行领域，让用户实时掌握老人和孩子乘坐公共交通的相关动态，工作生活更安心。</div>
        </div>

        <div className={'item'} onClick={() => history.push(Routes.solutionBigDataBusinessCabinet)}>
          <img src={img_2} alt=""/>
          <div className={'title'}>公务柜</div>
          <div className={'info'}>专为机关单位场景设计的智能公务投递系统，致力于提供零接触、授权式物品投取服务，畅通材料交转的“最后一百米”。</div>
        </div>
      </div> : <div className={'DigitalFinancePageContent'}>
        <div className={'digitalFinancePageBanner'}>
          <div className={`bannerItem ${active === 0 && 'active'}`}/>
          <div className={`bannerItem ${active === 1 && 'active'}`}/>
        </div>
        <div className={'infoContent'}>
          <div className={`item ${active === 0 && 'active'}`} onMouseEnter={() => setActive(0)}
               onClick={() => history.push(Routes.solutionBigDataRideSafety)}>
            <div className={'title'}>乘车安</div>
            <div className={'info'}>乘车安将家庭数字化服务延伸到公共出行领域，让用户实时掌握老人和孩子乘坐公共交通的相关动态，工作生活更安心。</div>
            <div className={`link ${active === 0 && 'active'}`}>
              <div>MORE</div>
              <img src={more_blue} alt=""/>
            </div>
          </div>
          <div className={`item ${active === 1 && 'active'}`} onMouseEnter={() => setActive(1)}
               onClick={() => history.push(Routes.solutionBigDataBusinessCabinet)}>
            <div className={'title'}>公务柜</div>
            <div className={'info'}>专为机关单位场景设计的智能公务投递系统，致力于提供零接触、授权式物品投取服务，畅通材料交转的“最后一百米”。</div>
            <div className={`link ${active === 1 && 'active'}`}>
              <div>MORE</div>
              <img src={more_blue} alt=""/>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
}
export default BigDataPage;
