import React from "react";
import './style.scss';

const BreadcrumbNavigation = (props = {data: []}) => {
  return <div className={'breadcrumbNavigation'}>
    <div className={'content'}>
      {
        props.data.map((value = {
          title: '', click: () => {
          }
        }, index) => {
          return <div className={'item'} onClick={value.click} key={`breadcrumbNavigation_${index}`}>
            <div className={'breadcrumbNavigationTitle'}>{value.title}</div>
            {
              index<props.data.length-1&&<div className={'icon'}>></div>
            }
          </div>
        })
      }
    </div>
  </div>;
}
export default BreadcrumbNavigation;
