import React, { useEffect, useState } from "react";
import "./style.scss";
import Axios from "../../../../utils/axios";
import Apis from "../../../../utils/apis";
import { useMounted } from "../../../../utils/hook";
// import cooperative_logo_1 from '../../../../assets/images/coooperate_logo1@2x.png';
// import cooperative_logo_2 from '../../../../assets/images/coooperate_logo2@2x.png';
// import cooperative_logo_3 from '../../../../assets/images/coooperate_logo3@2x.png';
// import cooperative_logo_4 from '../../../../assets/images/coooperate_logo4@2x.png';
// import cooperative_logo_5 from '../../../../assets/images/coooperate_logo5@2x.png';
// import cooperative_logo_6 from '../../../../assets/images/coooperate_logo6@2x.png';
// import cooperative_logo_7 from '../../../../assets/images/coooperate_logo7@2x.png';
// import cooperative_logo_9 from '../../../../assets/images/coooperate_logo9@2x.png';
// import cooperative_logo_10 from '../../../../assets/images/coooperate_logo10@2x.png';
// import cooperative_logo_11 from '../../../../assets/images/coooperate_logo11@2x.png';

const CooperativePartner = () => {
  const isMounted = useMounted();
  const [list, setList] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const res = await Axios({
        url: Apis.partners,
      });
      const { data = [], code = 200 } = res;
      if (code !== 200 || !isMounted) return false;
      setList(data);
    };

    (async () => {
      await getData();
    })();
  }, []);

  return (
    <div className={"CooperativePartner"}>
      <div className={"content"}>
        <div className={"headTitle"}>合作伙伴</div>
        <div className="list">
          {list.map((item) => (
            <div className="item">
              <img src={`${process.env["ASSETS_URL"]}${item.url}`} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CooperativePartner;
