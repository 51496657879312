import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Routes } from "../../../../utils/routes";
import isMobile from "ismobilejs";
import logo from "../../../../assets/images/logo@2x.png";
import logoActive from "../../../../assets/images/logo_blue@2x.png";
import back_top_png from "../../../../assets/home/back_top.png";

const CustomNavigation = () => {
  const [menus] = useState([
    {
      title: "首页",
      path: Routes.home,
    },
    {
      title: "关于我们",
      path: Routes.companyProfile,
    },
    {
      title: "解决方案",
      path: Routes.solution,
    },
    // {
    //   title: "解决方案V2",
    //   path: Routes.solutionV2,
    // },
    // {
    //   title: "战略合作",
    //   path: Routes.cooperation,
    // },
    {
      title: "社会公益",
      path: Routes.socialWelfare,
    },
    {
      title: "公司动态",
      path: `${Routes.news}/1`,
    }, // { title: '招贤纳士', path: Routes.joinUs, active: Routes.joinUs },
  ]);

  const [isActive, setIsActive] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleScroll = () => {
    if (window.scrollY > 50 && !isActive) {
      setIsActive(true);
      return false;
    }
    if (window.scrollY <= 10 && isActive) {
      setIsActive(false);
      return false;
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      handleScroll();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  });

  return (
    <div
      className={`CustomNavigation ${isActive ? "active showShadow" : ""} `}
      // className={`CustomNavigation ${
      //   (isActive ||
      //     (location.pathname !== Routes.home &&
      //       location.pathname !== Routes.socialWelfare &&
      //       location.pathname !== Routes.solution)) &&
      //   "active showShadow"
      // }`}
    >
      <div className={"content CustomNavigationBox"}>
        <Link to={Routes.home} className={"icon"}>
          <img
            className={`icon_image`}
            src={
              window.GLOBAL_WEB_INFO.logo
                ? `${process.env["ASSETS_URL"]}${window.GLOBAL_WEB_INFO.logo}`
                : logo
            }
            alt=""
          />
          <img
            className={`icon_image_active`}
            src={
              window.GLOBAL_WEB_INFO.logo_highlight
                ? `${process.env["ASSETS_URL"]}${window.GLOBAL_WEB_INFO.logo_highlight}`
                : logoActive
            }
            alt=""
          />
        </Link>
        {isMobile(window.navigator).phone ? (
          <div className={"itemIcon"} onClick={() => setShowMenu(true)} />
        ) : (
          <div className={"items"}>
            {menus.map((item = { path: "", title: "", active: "" }, index) => (
              <Link
                to={item.path}
                className={`item ${
                  location.pathname.indexOf(item.active || item.path) !== -1 &&
                  "active"
                }`}
                key={`menus_${index}`}
              >
                {item.title}
              </Link>
            ))}
          </div>
        )}
        {isMobile(window.navigator).phone ? (
          <div
            className={`mobileMenus ${showMenu ? "active" : ""}`}
            onClick={() => setShowMenu(false)}
          >
            <div
              className={"mobileMenusBox"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className={`mobileMenusClose`}
                onClick={() => setShowMenu(false)}
              />
              <div className={"mobileMenusItems"}>
                {menus.map(
                  (item = { path: "", title: "", active: "" }, index) => (
                    <div
                      onClick={() => {
                        setShowMenu(false);
                        history.push(item.path);
                      }}
                      className={`item ${
                        location.pathname.indexOf(item.active || item.path) !==
                          -1 && "active"
                      }`}
                      key={`menus_${index}`}
                    >
                      {item.title}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div
        className={`${
          isMobile(window.navigator).phone ? "backTop_mobile" : "backTop"
        } ${isActive ? "backTop_fade_in" : ""}`}
        onClick={() => {
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 100);
        }}
      />
    </div>
  );
};
export default CustomNavigation;
