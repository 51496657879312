import React from "react";
import './style.scss';
import isMobile from "ismobilejs";
import icon from "../../../../../../assets/images/top_bg3@2x.png";
import icon_2 from "../../../../../../assets/mobile/top_bg3@3x.png";
import TopBanner from "../../../../components/topBanner";
import BreadcrumbNavigation from "../../../../components/breadcrumbNavigation";
import {Routes} from "../../../../../../utils/routes";
import {useHistory} from 'react-router-dom';

const SolutionBigDataRideSafety = () => {
  const history = useHistory();
  return <div className={'SolutionBigDataRideSafety SolutionBigDataChild'}>
    <TopBanner icon={isMobile(window.navigator).phone ? icon_2 : icon} title={'解决方案'} english={'WELFARE'}/>
    <BreadcrumbNavigation data={[
      {title: '首页', click: () => history.push(Routes.home)},
      {title: '公共大数据', click: () => history.go(-1)},
      {title: '乘车安'},
    ]}/>
    <div className={'headTitle'}>不要焦急等待 就要看得见的关爱</div>
    <div className={'headLabel'}>乘车安，让无处安放的关心落在实处</div>
    <div className={'productBackground'}>
      <div className={'img'}/>
      <div className={'info'}>
        <div className={'title'}>产品背景</div>
        <div className={'msg'}>
          1、随着社会发展，道路交通情况越来越复杂，老年人行动缓慢、反应不及时，出行存在较多安全隐患。<br/>
          2、子女在外工作，不能经常陪伴在老人身边，老人出行的安全问题常常让子女担心不已。<br/>
          3、双职工家庭接送孩子不便，孩子独立乘车时无法及时掌握孩子具体乘车路线和时间。
        </div>
      </div>
    </div>
    <div className={'productIntroduction'}>
      <div className={'content'}>
        <div className={'title'}>产品介绍</div>
        <div className={'sub'}>国内首个码链融合家庭数字身份应用项目</div>
        <div
          className={'info'}>家音顺达凝聚国内大数据、区块链等领域顶级机构和专家的智慧力量和研究成果，聚焦老人、未成年人出行安全问题，打造国内首个码链融合家庭数字身份应用项目——乘车安，让用户实时掌握老人孩子乘坐公交车、地铁等公共交通的相关动态。
        </div>
      </div>
    </div>
    <div className={'productFeatures'}>
      <div className={'title'}>产品特点</div>
      <div className={'content'}>
        <div className={'item'}>
          实时推送孩子或老人乘车信息
          <div className={'hover'}>
            <h1>实时推送孩子或老人乘车信息</h1>
            <p>孩子或老人进出站后，系统实时向用户推送乘车人的进出站站点、路线、时间等信息。</p>
          </div>
        </div>
        <div className={'item'}>
          随时查看乘车地图与轨迹
          <div className={'hover'}>
            <h1>随时查看乘车地图与轨迹</h1>
            <p>系统可详细展现刷卡乘车位置，方便用户及时掌握孩子或老人的乘车轨迹。</p>
          </div>
        </div>
        <div className={'item'}>
          历史乘车记录一键查看
          <div className={'hover'}>
            <h1>历史乘车记录一键查看</h1>
            <p>用户可查看孩子或老人刷卡乘车的记录，包括刷卡时间、地点、乘车消费金额等。</p>
          </div>
        </div>

      </div>
    </div>
  </div>
}
export default SolutionBigDataRideSafety;
