import React from "react";
import "./index.scss";
import "./indexMobile.scss";
import bigData_icon from "../../../../../../assets/solution/bigData.png";
import yjs_icon from "../../../../../../assets/solution/yjs.png";
import qukl_icon from "../../../../../../assets/solution/qukl.png";
import rgzn_icon from "../../../../../../assets/solution/rgzn.png";
import inter_icon from "../../../../../../assets/solution/inter.png";
import pay_icon from "../../../../../../assets/solution/pay.png";
import _ from "lodash";
import isMobile from "ismobilejs";

const Data = [
  {
    icon: bigData_icon,
    title: "大数据",
    desc: "决策支持平台+智能预警平台，以数据驱动决策，实现校园资源的优化配置，减少运营成本。",
  },
  {
    icon: yjs_icon,
    title: "云计算",
    desc: "提供更强大的存储和运算能力，实现校园资源的统一管理、调度和开放共享，提高数据安全。",
  },
  {
    icon: qukl_icon,
    title: "区块链",
    desc: "构建安全可信的数据流通体系，可追溯不可篡改、可用不可见，为用户提供标准和统一的服务。",
  },
  {
    icon: rgzn_icon,
    title: "人工智能",
    desc: "自动完成例行任务，对数据进行深入学习，为用户提供匹配度更高的服务和产品。",
  },
  {
    icon: inter_icon,
    title: "物联网",
    desc: "通过设备联网，实现对校园终端设备的远程监控，提升校园安全，提高系统运行效率。",
  },
  {
    icon: pay_icon,
    title: "移动支付",
    desc: "通过银联、支付宝、微信支付等，解决学生在校无手机支付难题，提升校园生活品质。",
  },
];
export default function TechnologySystem() {
  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "technology_container_mobile"
          : "technology_container"
      }
    >
      <div className="technology_title">核心技术体系</div>
      <div className="technology_title_desc">
        智慧型、数据化、网络化、协作型、环境全面感知
      </div>
      {isMobile(window.navigator).phone && (
        <div className="technology_content_bg" style={{ marginTop: 60 }}>
          {_.map(Data, (item, i) => {
            return (
              <div className="technology_content_item" key={i}>
                <img className="technology_content_item_icon" src={item.icon} />
                <div className="technology_content_item_title">
                  {item.title}
                </div>
                <div className="technology_content_item_desc">{item.desc}</div>
              </div>
            );
          })}
        </div>
      )}
      {!isMobile(window.navigator).phone && (
        <div className="technology_content_bg" style={{ marginTop: 80 }}>
          {_.map([Data[0], Data[1], Data[2]], (item, i) => {
            return (
              <div className="technology_content_item" key={i}>
                <img className="technology_content_item_icon" src={item.icon} />
                <div className="technology_content_item_title">
                  {item.title}
                </div>
                <div className="technology_content_item_desc">{item.desc}</div>
              </div>
            );
          })}
        </div>
      )}
      {!isMobile(window.navigator).phone && (
        <div className="technology_content_bg" style={{ marginTop: 90 }}>
          {_.map([Data[3], Data[4], Data[5]], (item, i) => {
            return (
              <div className="technology_content_item" key={i}>
                <img className="technology_content_item_icon" src={item.icon} />
                <div className="technology_content_item_title">
                  {item.title}
                </div>
                <div className="technology_content_item_desc">{item.desc}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
