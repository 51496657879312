import React, { Component } from "react";
import { is, fromJS } from "immutable";
import ReactDOM from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.scss";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import isMobile from "ismobilejs";

let defaultState = {
  status: false,
  item: {},
};

class SwiperModalComponent extends Component {
  state = {
    ...defaultState,
  };
  // css动画组件设置为目标组件
  show = (item = {}) => {
    this.setState({
      status: true,
      item,
    });
    SwiperCore.use([Autoplay, Navigation, Pagination]);
  };
  hide = () => {
    this.setState({
      status: false,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !is(fromJS(this.props), fromJS(nextProps)) ||
      !is(fromJS(this.state), fromJS(nextState))
    );
  }

  render() {
    const { item, status } = this.state;
    return (
      <div className={"SwiperModal"}>
        {status && (
          <div
            className={`CompanyProfileTeamBuildingModal ${
              isMobile(window.navigator).phone
                ? "CompanyProfileTeamBuildingModalMobile"
                : ""
            }`}
            onClick={this.hide}
          >
            <div
              className={"modalContent"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={"close"} onClick={this.hide} />
              <div className={"title"}>{item.title}</div>
              <div className={"CompanyProfileTeamBuildingSwiper"}>
                <div className={"CompanyProfileTeamBuildingSwiperLeft"} />
                <div className={"CompanyProfileTeamBuildingSwiperContent"}>
                  <Swiper
                    className={"CompanyProfileTeamBuildingSwiperBox"}
                    autoplay={{ delay: 3000 }}
                    loop={true}
                    updateOnWindowResize={true}
                    resizeObserver={true}
                    navigation={{
                      nextEl: ".CompanyProfileTeamBuildingSwiperRight",
                      prevEl: ".CompanyProfileTeamBuildingSwiperLeft",
                    }}
                    pagination={{ clickable: true, el: ".paging" }}
                  >
                    {item.images &&
                      item.images.split(",").map((item, index) => (
                        <SwiperSlide key={`team_building_attachments_${index}`}>
                          <img
                            src={`${process.env["ASSETS_URL"]}${item}`}
                            alt=""
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className={"CompanyProfileTeamBuildingSwiperRight"} />
              </div>
              <div className={"paging"} />
              <div className={"text"}>{item.content}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let div = document.createElement("div");
let props = {};
document.body.appendChild(div);

let SwiperModal = ReactDOM.render(
  React.createElement(SwiperModalComponent, props),
  div
);
export default SwiperModal;
