import watch_icon from "../assets/solution/watch.png";
import shfw_icon from "../assets/solution/shfw.png";
import skuk_icon from "../assets/solution/skuk.png";
import school from "../assets/solution/school.png";
import school_icon from "../assets/solution/school_icon.png";
import parent from "../assets/solution/parent.png";
import teacher from "../assets/solution/teacher.png";

import safe_dorm from "../assets/solution/safe_dorm.png";
import safe_call from "../assets/solution/safe_call.png";
import safe_mind from "../assets/solution/safe_mind.png";
import safe_student from "../assets/solution/safe_student.png";
import safe_evaluate from "../assets/solution/safe_evaluate.png";
import safe_leave from "../assets/solution/safe_leave.png";
// life_ijx life_pay life_yjw life_yxg life_zhct life_zzfw
import life_ijx from "../assets/solution/life_ijx.png";
import life_pay from "../assets/solution/life_pay.png";
import life_yjw from "../assets/solution/life_yjw.png";
import life_yxg from "../assets/solution/life_yxg.png";
import life_zhct from "../assets/solution/life_zhct.png";
import life_zzfw from "../assets/solution/life_zzfw.png";

export const ManagerData = [
  {
    icon: watch_icon,
    title: "安全监管",
    slideId: "#safeId",
  },
  {
    icon: shfw_icon,
    title: "生活服务",
    slideId: "#lifeId",
  },
  {
    icon: skuk_icon,
    title: "四级预警",
    slideId: "#riskId",
  },
];
export const RoleData = [
  { icon: school, title: "教育局" },
  { icon: school_icon, title: "学校" },
  { icon: parent, title: "家长" },
  { icon: teacher, title: "老师" },
];
export const AppData = [
  { title: "新生入校", key: "1", slideId: "#safeId" },
  { title: "缺勤上报", key: "2", slideId: "#safeId" },
  { title: "学生请假", key: "3", slideId: "#safeId" },
  { title: "宿舍管理", key: "4", slideId: "#safeId" },
  { title: "行为评价", key: "5", slideId: "#safeId" },
  { title: "心理测试", key: "6", slideId: "#safeId" },
  { title: "四级预警", key: "7", slideId: "#riskId" },
  { title: "通知系统", key: "8", slideId: "" },
  { title: "家校通讯", key: "9", slideId: "#lifeId" },
  { title: "云家委", key: "14", slideId: "#lifeId" },
  { title: "智慧图书", key: "11", slideId: "" },
  { title: "校园收付", key: "12", slideId: "#lifeId" },
  { title: "云校柜", key: "13", slideId: "#lifeId" },
  { title: "智慧餐厅", key: "10", slideId: "#lifeId" },
  { title: "自助服务", key: "15", slideId: "#lifeId" },
];

export const SafetyData = [
  {
    title: "新生入校",
    key: "1",
    icon: safe_student,
    content: "一键线上报到 数据真实可靠",
    desc: "改变传统手动登记的新生信息采集模式，实现足不出户在线采集，大幅提升采集效率，保障数据的真实和可靠。",
  },
  {
    title: "缺勤上报",
    key: "2",
    icon: safe_call,
    content: "学生上课缺席，任课老师一键上报",
    desc: "系统自动过滤请假学生，并将学生缺席情况实时告知家长，有效减轻老师工作压力。",
  },
  {
    title: "学生请假",
    icon: safe_leave,
    key: "3",
    content: "家长老师双向审批，学生离校有通知、有记录",
    desc: "系统精准验证请假有效性，相关数据自动汇总并留存，方便管理和追溯。",
  },
  {
    title: "心理测评",
    key: "6",
    icon: safe_mind,
    content: "1分钟检测+12种情绪分析，既快又准",
    desc: "全面评估学生心理健康状态，对存在心理健康风险的学生进行筛查，同步建立学生心理健康档案。",
  },
  {
    title: "行为评价",
    key: "5",
    icon: safe_evaluate,
    content: "学生表扬/违纪，全面记录、综合分析",
    desc: "结合扫脸识人技术，让所有老师都能参与到每个学生的日常行为评价中，更便捷、更可靠。",
  },
  {
    title: "宿舍管理",
    key: "4",
    icon: safe_dorm,
    content: "查寝在线化，数据连通化",
    desc: "实现宿舍管理数字化，确保宿舍与校园其他系统互通互联，有效解决传统宿舍管理出现的数据不连通、成本高效率低等问题。",
  },
];
export const LifeData = [
  {
    title: "家校通讯",
    key: "9",
    icon: life_ijx,
    content: "让爱0距离，让亲情看得见",
    desc: "解决学生在校无手机状态下与家长的通讯难题，支持语音、视频、留言等形式，充分满足沟通需求。",
  },
  {
    title: "云校柜",
    key: "13",
    icon: life_yxg,
    content: "解决中小学生校园包裹最后100米",
    desc: "实现家长与孩子之间物品递送可追溯，有效解决家长向在校学生递送物品的杂乱现状及潜在的安全隐患。",
  },
  {
    title: "校园收付",
    key: "12",
    icon: life_pay,
    content: "减轻老师压力，促进财务活动统一管理",
    desc: "实现各类校园费用的在线收缴、结算和退费等，大大减少班主任在教学任务以外的工作负担。",
  },
  {
    title: "智慧餐厅",
    key: "10",
    icon: life_zhct,
    content: "“靠脸”吃饭，笔笔看得见",
    desc: "学生通过人脸识别即可就餐，避免因卡忘带、丢失等造成的各种不便，家长还能在线查看孩子就餐情况。",
  },
  {
    title: "云家委",
    key: "14",
    icon: life_yjw,
    content: "云上家委会，决策更透明",
    desc: "一站式解决家委会运行中出现的收费不透明、账目不清晰、家委工作负担重等问题。",
  },
  {
    title: "自助服务",
    key: "15",
    icon: life_zzfw,
    content: "提升校园生活品质，缓解学习压力",
    desc: "实现学生在校无手机状态下，自助地使用校园各类生活服务，包括洗衣机、吹风机、直饮水、用水用电等。",
  },
];
