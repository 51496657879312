import React from "react";
import './style.scss';
import isMobile from "ismobilejs";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import img_2 from "../../../../../assets/images/k12_img1@2x.png";
import img_3 from "../../../../../assets/images/k12_img2@2x.png";
import img_4 from "../../../../../assets/images/k12_img3@2x.png";
import img_5 from "../../../../../assets/images/k12_img4@2x.png";
import SwiperCore, {Autoplay, Navigation} from "swiper";

SwiperCore.use([Autoplay, Navigation]);
const K12ServiceCentrePage = () => {
  return <div className={'K12ServiceCentrePage'}>
    <div className={'headTitle'}>K12教育辅助解决方案</div>
    <div className={'headInfo'}>围绕基础教育中存在的课后延时、素质教育、营养配餐等问题，为学校和家庭提供优质、科学的教育辅助服务。</div>
    {/*课后延时管理*/}
    <div className={'delayManagementAfterClass'}>
      <div className={'head'}>
        <div className={'title'}>课后延时管理</div>
        <div className="info">解决“双职工”家庭孩子接送难题</div>
      </div>
      <div className="delayManagementAfterClassContent">
        <div className={'item'}>
          <div className={'itemContent'}>
            <div className={'icon'}/>
            <div className={'text'}>联合严选</div>
          </div>
          <div className={'hover'}>
            联合校方严选培训机构和教师，为家庭提供课后延时托管服务，保证辅导质量。
          </div>
        </div>
        <div className={'item'}>
          <div className={'itemContent'}>
            <div className={'icon'}/>
            <div className={'text'}>校内托管</div>
          </div>
          <div className={'hover'}>
            将课外辅导服务引进校园，实现学生课后校内托管，增强安全保障。
          </div>
        </div>
        <div className={'item'}>
          <div className={'itemContent'}>
            <div className={'icon'}/>
            <div className={'text'}>刷脸签到</div>
          </div>
          <div className={'hover'}>
            孩子在校刷脸即可完成签到，确保安全送托、接送。
          </div>
        </div>
        <div className={'item'}>
          <div className={'itemContent'}>
            <div className={'icon'}/>
            <div className={'text'}>安全放心</div>
          </div>
          <div className={'hover'}>
            家长还可在移动端，实时查看孩子在校动态，安全放心。
          </div>
        </div>
      </div>
    </div>
    {/*校园配餐*/}
    <div className={'campusCateringSystem'}>
      <div className="title">校园配餐系统</div>
      <div className="info">让孩子在学校吃上营养健康放心的食品</div>
      <div className={'campusCateringSystemContent'}>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'box'}>
            <div className={'inlineTitle'}>线上操作</div>
            <div className={'text'}>将所有的线下缴费、线下统计、意见反馈、退餐管理转为线上，人工转为智能。</div>
          </div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'box'}>
            <div className={'inlineTitle'}>食品安全</div>
            <div className={'text'}>家长可通过平台清晰看到食品溯源、食品生产加工等关键信息，确保食品安全。</div>
          </div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'box'}>
            <div className={'inlineTitle'}>简化管理</div>
            <div className={'text'}>学校简化流程，平台自动统计数据；商户自主管理订单，交易流水 明细自动统计。</div>
          </div>
        </div>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'box'}>
            <div className={'inlineTitle'}>个性化定制</div>
            <div className={'text'}>为学生提供个性化菜单定制，满足学生的不同营养需求。</div>
          </div>
        </div>
      </div>
    </div>
    {/*云家委*/}
    <div className={'cloudParents'}>
      <div className={'banner'}>
        <div className={'title'}>云家委</div>
        <div className={'info'}>建立学校与学生、家长之间沟通的桥梁</div>
        {isMobile(window.navigator).phone ? <div className={'tip'}>（左右滑动浏览）</div> : <div/>}
      </div>
      <div className={'cloudParentsContent'}>
        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>家长监课</div>
          <div className={'hover'}>
            <div className="textBox">随时随地了解课堂情况，孩子上课表现一键查看。</div>
          </div>
        </div>

        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>班级公告</div>
          <div className={'hover'}>
            <div className="textBox">阅读统计一目了然，未查阅家长一键提醒。</div>
          </div>
        </div>

        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>班级账本</div>
          <div className={'hover'}>
            <div className="textBox">班级账本，条目清晰，支持图文上传，形式更灵活。</div>
          </div>
        </div>

        <div className={'item'}>
          <div className={'icon'}/>
          <div className={'text'}>活动组织</div>
          <div className={'hover'}>
            <div className="textBox">家长在线报名，报名统计一目了然，未参与家长一键提醒。</div>

          </div>
        </div>
      </div>
    </div>

    {/*素质拓展服务*/}
    {
      isMobile(window.navigator).phone ? <div className={'qualityDevelopmentServiceMobile'}>
        <div className={'title'}>素质拓展服务</div>
        <div className={'headTitle'}>为中小学校提供个性化素质教育解决方案</div>
        <div className={'intelligentCommunicationContent'}>
          <div className={'intelligentCommunicationMobileSwiper'}>
            <Swiper
              autoplay={{delay: 2000, disableOnInteraction: false}}
              loop={true}
              updateOnWindowResize={true}
              resizeObserver={true}
              pagination={{clickable: true, el: '.intelligentCommunicationMobilePagination'}}
            >
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_2} alt=""/>
                  <div className={'bottom'}>
                    <h1>研学旅行</h1>
                    <p>让孩子们在旅行中拓展视野、丰富知识，加强书本知识和社会资源的深度结合。</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_3} alt=""/>
                  <div className={'bottom'}>
                    <h1>国际交流</h1> <p>增加学生对国际文化的了解，锻炼学生自我生存和国际交往能力。</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_4} alt=""/>
                  <div className={'bottom'}>
                    <h1>夏冬令营</h1> <p>让孩子在活动当中得到家庭和学校不同的锻炼和经历，有效提高综合素质。</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'item'}>
                  <img src={img_5} alt=""/>
                  <div className={'bottom'}>
                    <h1>成长营地</h1> <p>结合国际先进教育理念，通过体验式学习的方式，促进青少儿多元智能发展。</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={'intelligentCommunicationMobilePagination'}/>
        </div>
      </div> : <div className={'qualityDevelopmentService'}>
        <div className="title">素质拓展服务</div>
        <div className="info">为中小学校提供个性化素质教育解决方案</div>
        <div className="qualityDevelopmentServiceContent">
          <div className="item">
            研学旅行
            <div className={'hover'}>
              <div className={'title'}>研学旅行</div>
              <div className={'info'}>让孩子们在旅行中拓展视野、丰富知识，加强书本知识和社会资源的深度结合。</div>
            </div>
          </div>
          <div className="item">
            国际交流
            <div className={'hover'}>
              <div className={'title'}>国际交流</div>
              <div className={'info'}>增加学生对国际文化的了解，锻炼学生自我生存和国际交往能力。</div>
            </div>
          </div>
          <div className="item">
            夏冬令营
            <div className={'hover'}>
              <div className={'title'}>夏冬令营</div>
              <div className={'info'}>让孩子在活动当中得到家庭和学校不同的锻炼和经历，有效提高综合素质。</div>
            </div>
          </div>
          <div className="item">
            成长营地
            <div className={'hover'}>
              <div className={'title'}>成长营地</div>
              <div className={'info'}>结合国际先进教育理念，通过体验式学习的方式，促进青少儿多元智能发展。</div>
            </div>
          </div>
        </div>
      </div>
    }
    {/*阅读能力测评*/}
    <div className={'readingAbilityAssessment'}>
      <div className={'title'}>阅读能力测评</div>
      <div className={'info'}>提高学生阅读能力，优化老师教学策略</div>
      <div className={'readingAbilityAssessmentContent'}>
        <div className={'icon'}/>
        <div className={'list'}>
          <div className={'item'}>
            <div className="icon"/>
            <div className={'box'}>
              <div className="inlineTitle">精准</div>
              <div className="inlineInfo">通过数万学生测试，得出试题区分度、难度、测试度。</div>
            </div>
          </div>
          <div className={'item'}>
            <div className="icon"/>
            <div className={'box'}>
              <div className="inlineTitle">权威</div>
              <div className="inlineInfo">基于反应理论(IRT)，采用极大似然估计(MLE)精确测试学生阅读能力值。</div>
            </div>
          </div>
          <div className={'item'}>
            <div className="icon"/>
            <div className={'box'}>
              <div className="inlineTitle">全面</div>
              <div className="inlineInfo">包括提取信息、阐释推断、分析建构、鉴赏评价、迁移推断五大维度。</div>
            </div>
          </div>
          <div className={'item'}>
            <div className="icon"/>
            <div className={'box'}>
              <div className="inlineTitle">专业</div>
              <div className="inlineInfo">评测结果包含阅读能力值即时呈现、五大阅读能力水平全面展示、阅读能力历次对比。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
export default K12ServiceCentrePage;
