import React from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay, Navigation } from "swiper";

SwiperCore.use([Autoplay, Navigation]);

const CustomWelfareSwiper = (props = { data: [] }) => {
  return (
    <Swiper
      autoplay={{ delay: 2000 }}
      loop={true}
      updateOnWindowResize={true}
      resizeObserver={true}
      navigation
      className={"CustomWelfareSwiper"}
    >
      {props.data.map((item, index) => (
        <SwiperSlide key={`CustomWelfareSwiper_${index}`}>
          <img src={item} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default CustomWelfareSwiper;
