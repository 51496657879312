import React from "react";
import './style.scss';

const PublicWelfarePage = () => {
  return <div className={'PublicWelfarePage'}>
    <div className={'headTitle'}>新型公益解决方案</div>
    <div className={'headInfo'}>充分调动社会各界力量，打造可持续发展的新型公益实践中心，为整个社会需要关爱的人群，提供爱心帮扶。</div>
    <div className={'PublicWelfarePageContent'}>
      <div className={'item'}>
        <div className={'icon'}/>
        <div className={'text'}>全民读书会</div>
        <div className={'tip'}>建设中</div>
      </div>
      <div className={`item`}>
        <div className={'icon'}/>
        <div className={'text'}>家庭互助系统</div>
        <div className={'tip'}>建设中</div>
      </div>
      <div className={`item`}>
        <div className={'icon'}/>
        <div className={'text'}>残障关怀计划</div>
        <div className={'tip'}>建设中</div>
      </div>
      <div className={'item'}>
        <div className={'icon'}/>
        <div className={'text'}>志愿者体系</div>
        <div className={'tip'}>建设中</div>
      </div>
    </div>
  </div>
}
export default PublicWelfarePage;
