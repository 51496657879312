import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import "./mobileStyle.scss";
import NewComponent from "../../components/newComponet";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import bg from "../../../../assets/images/home_bg1.jpg";
import bg_2 from "../../../../assets/mobile/home_bg1@3x.png";
import "../../../mobile/style.scss";
import isMobile from "ismobilejs";
import NewsComponent from "../../components/newsComponet";
import PartnerCase from "../../components/partnerCase";
import { Routes } from "../../../../utils/routes";
import { useHistory } from "react-router-dom";
import _ from "lodash";
const DefaultMaskHeight = 220;
const DefaultMaskOffsetHeight = 120;

const HomePage = () => {
  const [maskHeight, setMaskHeight] = useState(0);
  const history = useHistory();
  const [opacity, setOpacity] = useState(0);
  const [title, setTitle] = useState("");

  const checkScrollTop = () => {
    // if (isMobile(window.navigator).phone) {
    //   setMaskHeight(0);
    //   return;
    // }
    if (window.scrollY > 0) {
      if (window.scrollY > 100) {
      }
      if (maskHeight !== DefaultMaskHeight) setMaskHeight(DefaultMaskHeight);
    } else {
      if (maskHeight !== 0) setMaskHeight(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [maskHeight]);

  const onTimeUpdate = (e) => {
    const currentTime = parseInt(e.target.currentTime);
    if (currentTime == 1) {
      setTitle("让校园更安全，让孩子更健康");
      setOpacity(1);
      return;
    }
    if (currentTime == 7) {
      setOpacity(0);
      return;
    }
  };

  const renderTopView = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div
          className="topBannerModular_mobile"
          style={
            maskHeight == DefaultMaskHeight
              ? {
                  // position: "fixed",
                  // left: 0,
                  // top: 0,
                  // zIndex: 10000,
                }
              : {}
          }
        >
          <video
            className="video_mobile"
            loop
            id="new_homeVideo1"
            playsInline
            autoPlay
            muted={true}
            onTimeUpdate={(e) => onTimeUpdate(e)}
            show-center-play-btn="false"
            enable-progress-gesture="false"
            poster="https://joinuscn-cdn.ijx.ink/joinuscn-admin/data/upload/video/jysd_home_video_2.mp4?x-oss-process=video/snapshot,t_1000,f_jpg"
            src="https://joinuscn-cdn.ijx.ink/joinuscn-admin/data/upload/video/jysd_home_video_2.mp4"
          />
          <div className="video_mask_mobile" />
          <div className="bg_mobile">
            <div className="bgTitle_mobile" style={{ opacity: opacity }}>
              {title && title.split("，")[0]}
            </div>
            <div
              className="bgTitle_mobile"
              style={{ opacity: opacity, marginTop: 5 }}
            >
              {title && title.split("，")[1]}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`topBannerModular`}>
        <div className={"bgImg"}>
          <div className={"bgTitle"} style={{ opacity: opacity }}>
            {title}
          </div>
        </div>
        <div className={"bg"}>
          {/* <img src={isMobile(window.navigator).phone ? bg_2 : bg} alt="" /> */}
          <video
            className={"video"}
            loop
            id="new_homeVideo"
            playsInline
            autoPlay
            muted={true}
            onTimeUpdate={(e) => onTimeUpdate(e)}
            show-center-play-btn="false"
            enable-progress-gesture="false"
            poster="https://joinuscn-cdn.ijx.ink/joinuscn-admin/data/upload/video/jysd_home_video_2.mp4?x-oss-process=video/snapshot,t_1000,f_jpg,w_1920,h_1080"
            src="https://joinuscn-cdn.ijx.ink/joinuscn-admin/data/upload/video/jysd_home_video_2.mp4"
          ></video>
          <div className="video_mask" />
        </div>
        <div className={"scrollBar"}></div>
      </div>
    );
  };

  const renderNewsView = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <NewsComponent hiddenTitle={maskHeight == 0} />
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: 800,
            top: `-${
              maskHeight == DefaultMaskHeight ? DefaultMaskHeight : 0
            }px`,
            width: "100%",
            transition: "top 1s",
            backgroundColor: "#f8f8f8",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: `-${
              maskHeight == DefaultMaskHeight
                ? DefaultMaskHeight - 20
                : DefaultMaskOffsetHeight
            }px`,
            transition: "margin-top 1s",
            justifyContent: "center",
          }}
        >
          <NewsComponent hiddenTitle={maskHeight == 0} />
        </div>
      </div>
    );
  };

  const renderK12View = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div className="k12_modular_mobile">
          <div className="k12_modular_content_mobile">
            <div className="title_mobile">K12校园全场景数字化服务运营专家</div>
            <div className="sub_title_mobile">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              家音顺达始创于2010年，是一家集研发、服务、运营为一体的创新型互联网企业，专注于K12校园数字化领域，创新打造了以学生校园安全、校园生活服务为核心的K12校园安全生活服务平台。
            </div>
            <div
              className="more_bg_mobile"
              onClick={() => {
                history.push(Routes.companyProfile);
              }}
            >
              <div className="more_title_mobile">查看更多</div>
              <div className="more_img_mobile" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="k12_modular">
        <div className="k12_modular_content">
          <div className="title">K12校园全场景数字化服务运营专家</div>
          <div className="sub_title">
            家音顺达始创于2010年，是一家集研发、服务、运营为一体的创新型互联网企业，专注于K12校园数字化领域，创新打造了以学生校园安全、校园生活服务为核心的K12校园安全生活服务平台。
          </div>
          <div
            className="more_bg"
            onClick={() => {
              history.push(Routes.companyProfile);
            }}
          >
            <div className="more_title">查看更多</div>
            <div className="more_img" />
          </div>
        </div>
      </div>
    );
  };

  // 底部导航
  const renderBottomNav = () => {
    const navs = [
      { title: "关于我们", path: Routes.companyProfile },
      { title: "解决方案", path: Routes.solution },
      { title: "社会公益", path: Routes.socialWelfare },
      { title: "公司动态", path: `${Routes.news}/1` },
    ];
    return (
      <div className="bottom_nav_mobile">
        {_.map(navs, (item, index) => {
          return (
            <div
              key={index}
              className="bottom_nav_item_mobile"
              onClick={() => {
                history.push(item.path);
              }}
            >
              <div className="title">{item.title}</div>
              <div className="icon" />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={
        isMobile(window.navigator).phone ? "homePage_mobile" : "homePage"
      }
    >
      {/*顶部banner*/}
      {renderTopView()}

      {/* 最新动态*/}
      {renderNewsView()}

      {/* k12 校园 */}
      {renderK12View()}

      {/* 合作伙伴 */}
      <PartnerCase />
      {/* 底部导航 */}
      {isMobile(window.navigator).phone && renderBottomNav()}
    </div>
  );
};
export default HomePage;
