export default class Apis {
  //获取首页新闻数据
  static homeNews = "/api/v1/public/home";
  //获取新闻列表
  static news = "/api/v1/public/news";
  //获取合作伙伴
  static partners = "/api/v1/public/partners";
  //获取服务学校
  static schools = "/api/v1/public/schools";
  //团队建设
  static teamBuildings = "/api/v1/public/team_buildings";
  //一本书公益
  static socialWelfare = "/api/v1/public/social_welfares";
  //战略合作
  static cooperation = "/api/v1/public/cooperations";
  //招贤纳士
  static jobs = "/api/v1/public/jobs";
  //荣誉资质
  static honorary = "/api/v1/public/honors";
}
