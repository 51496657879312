import React from "react";
import "./style.scss";
import isMobile from "ismobilejs";

const CompanyProfileIndexPage = () => {
  const haveBr = (isPcBr) => {
    if (isMobile(window.navigator).phone) {
      if (isPcBr) {
        return "";
      } else {
        return <br />;
      }
    } else {
      if (isPcBr) {
        return <br />;
      } else {
        return "";
      }
    }
  };

  return (
    <div className={"CompanyProfileIndexPage"}>
      {/* <div className={"title"}>
        {window.GLOBAL_WEB_INFO.name || "北京家音顺达数据技术有限公司"}
      </div> */}
      <div className={"text"}>
        <p>
          家音顺达始创于2010年，是一家集研发、服务、运营为一体的创新型互联网企业，专注于K12校园数字化领域，创新打造了包含学生考勤、请假访校、宿舍管理、行为评价、心理评测、家校通讯、大数据预警、无手机支付、校园生活服务等在内的K12校园安全生活服务平台。
        </p>
        <p>
          本着以客户为中心的经营理念，公司凝聚了一支高标准、高效率、高素质的技术研发团队和服务运营团队，打造了技术研发、市场开拓、项目实施、运营维护、售后保障等校园全场景数字化一站式服务运营体系，致力为客户提供更优质、更便捷、更周到的服务。
        </p>
        <p>
          未来，公司将进一步探索由校园数字化向家庭数字化转型的发展模式，打造数字家庭身份体系，构建全场景数字家庭服务平台，为家庭提供更便利的数字化服务，为“加快数字化发展、建设数字中国”宏伟蓝图奉献力量。
        </p>
        {/* <p>家音顺达是一家专注于家庭数字化场景应用，集研发、服务、运营为一体的创新型互联网企业。主营业务以家庭数字化服务为核心，渗透家庭生活的方方面面，包含校园、安全、教育、出行、金融等场景，致力于打造家庭数字化新生活。</p>
      <p>公司坚持技术创新为发展源动力，凝聚了一支高学历、高素质、高技能的技术研发团队，主营软件和产品均拥有独立知识产权，并通过了国家级软件质量测试。</p>
      <p>围绕“数字改变生活”的理念，公司打造了数字化校园服务中心、均衡教育创新服务中心、K12教育{haveBr(true)}辅助服务中心、公共大数据服务中心等中心，业务已覆盖北京、上海、河北、山东、河南{haveBr(true)}等地，旗下各品牌和产品深受广大用户好评和喜爱。</p>
      <p>今天的人们已离不开便捷、安全的数字化服务，这是家音顺达不懈努力的方向。未来，{haveBr(true)}公司将深度布局家庭数字化服务生态圈，为家庭用户提供便利的数字化服务，{haveBr(true)}为“‘十四五’加快数字化发展、建设数字中国宏伟蓝图”奉献力量。</p>
      <br/>
      <p className={"small"}>（注：文中涉及资质荣誉和数据包含公司、分公司及控股子公司的相关内容。）</p> */}
        {/*<p>家音顺达是一家专注于家庭数字化场景应用，集研发、服务、运营为一体的创新型互联网企业。主营业务以家庭数字化服务为核心，渗透家庭生活的方方面面，包含校园、安全、教育、出行、金融等场景，致力于打造家庭数字化新生活。</p>*/}
        {/*<p>公司坚持技术创新为发展源动力，凝聚了一支高学历、高素质、高技能的技术研发团队，主营软件和产品均拥有独立知识产权，并通过了国家级软件质量测试，获得各类专利和计算机软件著作权100余项，是{haveBr(true)}“高新技术企业”和“双软认证企业”。</p>*/}
        {/*<p>围绕“数字改变生活”的理念，公司打造了数字化校园服务中心、均衡教育创新服务中心、K12{haveBr(true)}教育辅助服务中心、公共大数据服务中心等中心，业务已覆盖北京、上海、河北、山东、河南{haveBr(true)}等地，服务学校4000余所，服务家庭500万多个，旗下各品牌和产品深受广大用户好评和{haveBr(true)}喜爱。</p>*/}
        {/*<p>今天的人们已离不开便捷、安全的数字化服务，这是家音顺达不懈努力{haveBr(true)}的方向。未来，公司将深度布局家庭数字化服务生态圈，为家庭用户{haveBr(true)}提供便利的数字化服务，为“‘十四五’加快数字化发展、建设数字{haveBr(true)}中国宏伟蓝图”奉献力量。</p>*/}
        {/*<p>（注：文中涉及资质荣誉和数据包含公司、分公司及{haveBr(true)}控股子公司的相关内容。）</p>*/}
        {/*<p>家音顺达是一家专注于以家庭为服务对象的数字化场景应用，集研发、服务、运营为一体的创新型互联{haveBr(true)}*/}
        {/*  网企业。主营业务以家庭数字化服务为核心，渗透家庭生活的方方面面，包含校园、安全、教育、出行、{haveBr(true)}*/}
        {/*  金融等场景，致力于打造家庭数字化新生活。*/}
        {/*</p>*/}
        {/*<p>公司坚持技术创新为发展源动力，凝聚了一支高学历、高素质、高技能的技术研发团队，主营软件和产{haveBr(true)}*/}
        {/*  品均拥有独立知识产权，并通过了国家级软件质量测试，荣获各类专利、计算机软件著作权等100余项，{haveBr(true)}*/}
        {/*  是“高新技术企业”和“双软认证企业”。*/}
        {/*</p>*/}
        {/*<p>围绕“数字赋能、美好生活”的理念，公司打造了数字化校园服务 中心、均衡教育创新服务{haveBr(true)}*/}
        {/*  中心、K12教育辅助服务中心、公共大数据服务中心等四大中心，业务已覆盖北京、上海、{haveBr(true)}*/}
        {/*  河北、山东、河南等地，累计服务1500万多个家庭，旗下各品牌和产品深受广大{haveBr(true)}*/}
        {/*  用户好评和喜爱。*/}
        {/*</p>*/}
        {/*<p>今天的人们已离不开便捷、安全的数字化服务，这是家音顺达{haveBr(true)}*/}
        {/*  不懈努力的方向。未来，{haveBr(false)}公司将深度布局家庭数字化服务{haveBr(false)}生态圈，{haveBr(true)}*/}
        {/*  为家庭用户提供便利的{haveBr(false)}数字化服务，为“‘十四五’加{haveBr(false)}快数字化{haveBr(true)}*/}
        {/*  发展、建设{haveBr(false)}数字中国宏伟蓝图”{haveBr(false)}奉献力量。*/}
        {/*</p>*/}
      </div>
    </div>
  );
};
export default CompanyProfileIndexPage;
