import React from "react";
import './style.scss';
import isMobile from "ismobilejs";

const DigitalMedicinePage = () => {
  return <div className={'DigitalMedicinePage'}>
    <div className={'headTitle'}>数字化医疗解决方案</div>
    <div className={'headInfo'}>凭“一张脸”就能看病、结算、取药，只要一部智能手机就能在家购药，让传统的“重医轻防”向“医防并重”转变。</div>
    <div className={'digitalMedicinePageContent'}>

      <div className={'item'}>
        <div className={'itemContent'}>{isMobile(window.navigator).phone ? '' : '数字化医院'}</div>
        <div className={'hover'}>
          <div className={'title'}>数字化医院</div>
          <div className={'info'}>提供在线图文、语音、视频咨询，网络门诊、合理用药等功能，使居民可以足不出户实现线上问诊、电子处方以及快递送药到家。</div>
        </div>
      </div>

      <div className={'item'}>
        <div className={'itemContent'}>{isMobile(window.navigator).phone ? '' : '智能预约挂号'}</div>
        <div className={'hover'}>
          <div className={'title'}>智能预约挂号</div>
          <div className={'info'}>根据居民疾病症状为患者推荐科室医生，通过统一预约挂号平台对线上线下医生和号源进行管理，居民可以直接进行线上线下号源预约。</div>
        </div>
      </div>

      <div className={'item'}>
        <div className={'itemContent'}>{isMobile(window.navigator).phone ? '' : '互联网护理'}</div>
        <div className={'hover'}>
          <div className={'title'}>互联网护理</div>
          <div className={'info'}>针对行动不便的市民，提供居家网约护理服务，如上门打针、输液护理等，与此同时，平台也会实时记录护士位置，保障护士安全。</div>
        </div>
      </div>

      <div className={'item'}>
        <div className={'itemContent'}>{isMobile(window.navigator).phone ? '' : '远程协同医疗'}</div>
        <div className={'hover'}>
          <div className={'title'}>远程协同医疗</div>
          <div
            className={'info'}>建设完善双向转诊系统（门诊转诊、住院转诊、手术转诊）、远程门诊系统（含终端硬件）、远程会诊系统、多学科联合会诊（MDT）服务、远程视频查房系统（含终端硬件）、医联/共体临床协同服务和医联/共体护理协同服务。
          </div>
        </div>
      </div>

    </div>
  </div>
}
export default DigitalMedicinePage;
