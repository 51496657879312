import React, { useEffect } from "react";
import CustomNavigation from "./components/navigation";
import "./style.scss";
import Footer from "./components/footer";
import { Route, Switch, withRouter } from "react-router-dom";
import HomePage from "./pages/home";
import CompanyProfilePage from "./pages/companyProfile";
import { Routes } from "../../utils/routes";
import CooperationPage from "./pages/cooperation";
import SolutionPage from "./pages/solution";
import SolutionPageV2 from "./pages/solutionV2";
import SocialWelfarePage from "./pages/socialWelfare";
import JoinUsPage from "./pages/joinUs";
import JoinUsHomePage from "./pages/joinUs/home";
import NewsPage from "./pages/news";
import NewsDetailPage from "./pages/news/detail";
import isMobile from "ismobilejs";
import SolutionBigDataRideSafety from "./pages/solution/bigData/rideSafety";
import SolutionBigDataBusinessCabinet from "./pages/solution/bigData/businessCabinet";

const Pc = () => {
  useEffect(() => {
    const currentScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      window.scrollTo(0, 0);
    }
  });

  return (
    <div className={`${isMobile(window.navigator).phone ? "mobile" : "pc"}`}>
      <CustomNavigation />
      <Switch>
        <Route path={Routes.home} component={HomePage} />
        <Route path={Routes.companyProfile} component={CompanyProfilePage} />
        <Route path={Routes.cooperation} component={CooperationPage} />
        <Route path={Routes.solution} component={SolutionPageV2} />
        {/* <Route path={Routes.solutionV2} component={SolutionPageV2} /> */}
        <Route path={Routes.socialWelfare} component={SocialWelfarePage} />
        <Route path={Routes.joinUs} component={JoinUsPage} />
        <Route path={Routes.joinUsHome} component={JoinUsHomePage} />
        <Route path={`${Routes.news}/:page`} component={NewsPage} />
        <Route path={`${Routes.newsDetail}/:id`} component={NewsDetailPage} />

        <Route
          path={Routes.solutionBigDataRideSafety}
          component={SolutionBigDataRideSafety}
        />
        <Route
          path={Routes.solutionBigDataBusinessCabinet}
          component={SolutionBigDataBusinessCabinet}
        />
      </Switch>

      <Footer />
    </div>
  );
};
export default withRouter(Pc);
