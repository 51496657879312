import React from "react";
import "./index.scss";
import "./indexMobile.scss";
import _ from "lodash";
import lft_icon from "../../../../../../assets/solution/lft.png";
import group_arrow from "../../../../../../assets/solution/group_arrow.png";
import group_arrow_mobile from "../../../../../../assets/solution/mobile/Group_mobile.png";
import risk_data_icon1 from "../../../../../../assets/solution/risk_data_icon1.png";
import risk_data_icon2 from "../../../../../../assets/solution/risk_data_icon2.png";
import risk_data_icon3 from "../../../../../../assets/solution/risk_data_icon3.png";
import risk_data_img from "../../../../../../assets/solution/risk_data_img.png";
import isMobile from "ismobilejs";

const TitleData = [
  { icon: risk_data_icon1, title: "多元大数据" },
  { icon: risk_data_icon2, title: "大数据算法" },
  { icon: risk_data_icon3, title: "四级风险预警" },
];
const ModelLeft = [
  "考勤请假",
  "行为评价",
  "生活消费",
  "心理健康评测",
  "其他校园大数据",
];
const WarnLeft = ["家长", "班主任", "校长", "教育部门"];
const WarnRight = ["短信通知", "公众号通知", "APP通知", "语音电话"];
const bubbleList = [{ key: 1, top: 0, left: 0, width: 100, height: 100 }];
export default function RiskSystem(props) {
  const { slideId } = props;

  const renderRiskContentBg = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="risk_content_bg">
            <div className="risk_content_title_bg" style={{ marginTop: 60 }}>
              {_.map([TitleData[0], TitleData[1]], (item, i) => {
                return (
                  <div className="risk_content_title_item" key={i}>
                    <img
                      className="risk_content_title_item_icon"
                      src={item.icon}
                    />
                    <div className="risk_content_title_item_title">
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="risk_content_bg">
            <div
              className="risk_content_title_bg"
              style={{ marginBottom: 10, marginTop: 20 }}
            >
              {_.map([TitleData[2]], (item, i) => {
                return (
                  <div className="risk_content_title_item" key={i}>
                    <img
                      className="risk_content_title_item_icon"
                      src={item.icon}
                    />
                    <div className="risk_content_title_item_title">
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="risk_content_bg">
        <div className="risk_content_title_bg">
          {_.map(TitleData, (item, i) => {
            return (
              <div className="risk_content_title_item" key={i}>
                <img className="risk_content_title_item_icon" src={item.icon} />
                <div className="risk_content_title_item_title">
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isMobile(window.navigator).phone
          ? "risk_container_mobile"
          : "risk_container"
      }
      id={slideId}
    >
      {!isMobile(window.navigator).phone && (
        <img className="risk_img" src={risk_data_img} />
      )}
      <div className="risk_title">学生安全风险多元大数据预警系统</div>
      <div className="risk_title_desc">
        学生校园大数据，全接入、全分析、全预警
      </div>
      {renderRiskContentBg()}

      <div className="risk_model_bg">
        <div className="risk_model_left_bg">
          {_.map(ModelLeft, (item, i) => {
            return (
              <div className="risk_model_left_item" key={i}>
                <div className="risk_model_left_item_title">{item}</div>
              </div>
            );
          })}
        </div>
        {isMobile(window.navigator).phone && (
          <div className="risk_model_middle_bg">
            <img className="risk_model_middle_arrow" src={group_arrow_mobile} />
            <div className="risk_model_middle_title" style={{ marginTop: 20 }}>
              行为风险分析算法
            </div>
            <div className="risk_model_middle_title" style={{ marginTop: 5 }}>
              学生安全动态预警模型
            </div>
            <div className="risk_model_middle_content_level">
              {_.map(["低", "中", "高"], (item, i) => {
                return (
                  <div className="risk_model_middle_content_level_item" key={i}>
                    {item}
                  </div>
                );
              })}
            </div>
            <img
              className="risk_model_middle_arrow"
              src={group_arrow_mobile}
              style={{ marginTop: 28 }}
            />
          </div>
        )}
        {!isMobile(window.navigator).phone && (
          <div className="risk_model_middle_bg">
            <img
              className="risk_model_middle_arrow"
              src={group_arrow}
              style={{ marginRight: 40 }}
            />
            <div className="risk_model_middle_content">
              <div className="risk_model_middle_content_title">
                行为风险分析算法
              </div>
              <div
                className="risk_model_middle_content_title"
                style={{ marginTop: 17 }}
              >
                学生安全动态预警模型
              </div>
              <div className="risk_model_middle_content_level">
                {_.map(["低", "中", "高"], (item, i) => {
                  return (
                    <div
                      className="risk_model_middle_content_level_item"
                      key={i}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className="risk_model_middle_content_icon">
                <div className="risk_model_middle_content_icon_ani">
                  {_.map(new Array(6), (item, i) => {
                    const wList = [5, 9, 6, 12, 10, 8];
                    const randomElement =
                      wList[Math.floor(Math.random() * wList.length)];
                    const delay = Math.random() * (1.8 - 0.1) + 0.1;

                    const s = {
                      left: `${Math.random() * 100}%`,
                      width: randomElement,
                      height: randomElement,
                      animationDuration: "6s",
                      animationDelay: `${delay}s`,
                    };
                    return <div class="risk_bubble" key={i} style={s} />;
                  })}
                </div>
                <div className="risk_model_middle_content_icon_ani">
                  {_.map(new Array(6), (item, i) => {
                    const wList = [5, 9, 6, 12, 10, 8];
                    const randomElement =
                      wList[Math.floor(Math.random() * wList.length)];
                    const delay = Math.random() * (1.8 - 0.1) + 0.1;

                    const s = {
                      left: `${Math.random() * 100}%`,
                      width: randomElement,
                      height: randomElement,
                      animationDuration: "6s",
                      animationDelay: `${delay + 3}s`,
                    };
                    return <div class="risk_bubble" key={i} style={s} />;
                  })}
                </div>
              </div>
            </div>
            <img className="risk_model_middle_arrow" src={group_arrow} />
          </div>
        )}

        <div className="risk_model_right_bg">
          <div className="risk_model_right_warning">
            <div className="risk_model_right_warning_title">- 预警对象 -</div>
            {_.map(WarnLeft, (item, i) => {
              return (
                <div className="risk_model_right_warning_item" key={i}>
                  <div className="risk_model_right_warning_item_title">
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="risk_model_right_warning" style={{ marginLeft: 40 }}>
            <div className="risk_model_right_warning_title">- 预警方式 -</div>
            {_.map(WarnRight, (item, i) => {
              return (
                <div className="risk_model_right_warning_item" key={i}>
                  <div className="risk_model_right_warning_item_title">
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
